import React, {useState} from "react";
import { Link } from "react-router-dom"
import Navbar from "../../../component/navbar/Navbar"
import faq from "../../../assets/faq.png"
import Footer from "../../../component/footer/Footer";
import NewsLetter from "../../../component/news-letter/NewsLetter";
import "./tarifs.scss"
import DoneSharpIcon from '@mui/icons-material/DoneSharp';
import KeyboardArrowDownSharpIcon from '@mui/icons-material/KeyboardArrowDownSharp';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import ClearIcon from '@mui/icons-material/Clear';


function Tarifs() {
    const [displayResp, setDisplayResp] = useState([]);
    const questions = [
        {
            id:2,
            questions:"Quelles sont les offres ?",
            reponses:`Les offres dépendent de vos besoins. Nous proposons des offres personnalisées répondant à vos différents 
            besoins tout en restant dans une marge de prix très abordable. Vous pouvez demander un devis gratuit pour votre
             projet et après cela vous serez en mesure de juger si l’offre proposée vous convient ou pas et nous resterons 
             toujours disponible pour de potentielle discussion.`
        },
        {
            id:3,
            questions:"Quelles sont les procedures d'un contrat avec nous?",
            reponses:`Pour démarrer un contrat avec nous, vous devez tout d’avoir prendre contact et nous faire part de vos besoins. 
            Ensuite on vous proposera un devis dans les plus bref délai. Après le devis, nous discuterons sur certaines modalités 
            et le travail pourra commencer. `
        },
        {
            id:4,
            questions:"Est-ce que je peux avoir une tarification personnalisée ?",
            reponses:`Oui tout à fait et c’est ce que nous priorisons le plus. Etant donné que les entreprises se diffère les uns 
            des autres il est tout à fait normal que les besoins aussi diffère c’est pour cela que nous prenons toujours le temps 
            d’analyser vos besoins afin de vous offrir des offres personnalisées qui vous conviendra`
        },
        {
            id:5,
            questions:"Est-ce qu'une possibilité de collaboration est envisageable ?",
            reponses:`Au-delà des offres que nous vous proposons, il est aussi possible d’établir un partenariat avec nous pour 
            la mise en place de votre projet notamment les projets naissants et si notre structure est séduite par votre idée, 
            elle vous aidera et vous accompagnera dans la réalisation de celle-ci.`
        },
        {
            id:6,
            questions:"Comment prendre contact ?",
            reponses:`Vous pouvez nous envoyer un message directement en allant sur la page contact ou en nous envoyant un mail sur 
            contact@dev-mastermind.com ou encore par WhatsApp sur le +226 72528159 . Vous pouvez aussi nous contacter en utilisant nos 
            réseaux sociaux qui se trouve en pied de page`
        }
    
    ]
    const handleResp = (id) => {
        if (displayResp.includes(id)) {
          setDisplayResp(displayResp.filter((qid) => qid !== id));
        } else {
          setDisplayResp([...displayResp, id]);
        }
    };
    return(
        <React.Fragment>
            <div className="tarifs">
                <Navbar page="tarifs"/>
                <div className="ordinateur">
                    <div className="first-element">
                        <div className="top">
                            <span>Tarifs</span>
                            <p>Vous méritez d'avoir le meilleure accompagnement sans débourser 
                                une fortune, nous nous engageons à vous fournir un travail de qualité à prix abordable</p>
                        </div>
                        <div className="bottom">
                            <div className="box1">
                                <div className="title">Basic</div>
                                <div className="price">
                                    <AttachMoneyIcon />
                                </div>
                                <div className="desc">Pour les projets personnels</div>
                                <Link to="/contact"><button>Demander un devis</button></Link>
                            </div>
                            <div className="box2">
                                <div className="popular">Plus Populaire</div>
                                <div className="title">Pro</div>
                                <div className="price">
                                    <AttachMoneyIcon />
                                    <AttachMoneyIcon />
                                </div>
                                <div className="desc">Pour les petites et moyennes entreprises</div>
                                <Link to="/contact"><button>Demander un devis</button></Link>
                            </div>
                            <div className="box3">
                                <div className="title">Business</div>
                                <div className="price">
                                    <AttachMoneyIcon />
                                    <AttachMoneyIcon />
                                    <AttachMoneyIcon />
                                </div>
                                <div className="desc">Pour les grandes entreprises</div>
                                <Link to="/contact"><button>Demander un devis</button></Link>
                            </div>
                        </div>
                    </div>
                    <div className="second-element">
                        <h2>Comparer les offres</h2>
                        <div className="content">
                            <div className="box">
                                <div className="title">Basic</div>
                                <hr />
                                <div className="offre">
                                    <DoneSharpIcon className="icon"/>
                                    <div>Logo si besoin </div>
                                </div>
                                <div className="offre">
                                    <DoneSharpIcon className="icon"/>
                                    <div>Design du site</div>
                                </div>
                                <div className="offre">
                                    <DoneSharpIcon className="icon"/>
                                    <div>Utilisation de CMS</div>
                                </div>
                                <div className="offre">
                                    <DoneSharpIcon className="icon"/>
                                    <div>5 pages maximum</div>
                                </div>
                                <div className="offre">
                                    <DoneSharpIcon className="icon"/>
                                    <div>Nom de domaine offert pour la première année</div>
                                </div>
                                <div className="offre">
                                    <DoneSharpIcon className="icon"/>
                                    <div>Hébergement offert pour le premier mois</div>
                                </div>
                                <div className="offre">
                                    <DoneSharpIcon className="icon"/>
                                    <div>1 mois de suivi gratuit après livraison</div>
                                </div>
                                <div className="offre">
                                    <DoneSharpIcon className="icon"/>
                                    <div>Interface administrateur</div>
                                </div>
                            </div>
                            <div className="box">
                                <div className="title">Pro</div>
                                <hr />
                                <div className="offre">
                                    <DoneSharpIcon className="icon"/>
                                    <div>Logo si besoin </div>
                                </div>
                                <div className="offre">
                                    <DoneSharpIcon className="icon"/>
                                    <div>Design du site</div>
                                </div>
                                <div className="offre">
                                    <DoneSharpIcon className="icon"/>
                                    <div>Developpement personnalisé</div>
                                </div>
                                <div className="offre">
                                    <DoneSharpIcon className="icon"/>
                                    <div>10 pages maximum</div>
                                </div>
                                <div className="offre">
                                    <DoneSharpIcon className="icon"/>
                                    <div>Nom de domaine offert pour la première année</div>
                                </div>
                                <div className="offre">
                                    <DoneSharpIcon className="icon"/>
                                    <div>Hébergement offert pour la première année</div>
                                </div>
                                <div className="offre">
                                    <DoneSharpIcon className="icon"/>
                                    <div>2 mois de suivi gratuit après livraison</div>
                                </div>
                                <div className="offre">
                                    <DoneSharpIcon className="icon"/>
                                    <div>Interface administrateur</div>
                                </div>
                                <div className="offre">
                                    <DoneSharpIcon className="icon"/>
                                    <div>Intégration mode dark et light</div>
                                </div>
                                <div className="offre">
                                    <DoneSharpIcon className="icon"/>
                                    <div>Base de données intégrées</div>
                                </div>
                                <div className="offre">
                                    <DoneSharpIcon className="icon"/>
                                    <div>Développement back end</div>
                                </div>
                            </div>
                            <div className="box">
                                <div className="title">Business</div>
                                <hr />
                                <div className="offre">
                                    <DoneSharpIcon className="icon"/>
                                    <div>Logo si besoin </div>
                                </div>
                                <div className="offre">
                                    <DoneSharpIcon className="icon"/>
                                    <div>Design du site</div>
                                </div>
                                <div className="offre">
                                    <DoneSharpIcon className="icon"/>
                                    <div>Developpement personnalisé</div>
                                </div>
                                <div className="offre">
                                    <DoneSharpIcon className="icon"/>
                                    <div>20 pages maximum</div>
                                </div>
                                <div className="offre">
                                    <DoneSharpIcon className="icon"/>
                                    <div>Nom de domaine offert pour la première année</div>
                                </div>
                                <div className="offre">
                                    <DoneSharpIcon className="icon"/>
                                    <div>Hébergement offert pour la première année</div>
                                </div>
                                <div className="offre">
                                    <DoneSharpIcon className="icon"/>
                                    <div>3 mois de suivi gratuit après livraison</div>
                                </div>
                                <div className="offre">
                                    <DoneSharpIcon className="icon"/>
                                    <div>Interface administrateur</div>
                                </div>
                                <div className="offre">
                                    <DoneSharpIcon className="icon"/>
                                    <div>Intégration mode dark et light</div>
                                </div>
                                <div className="offre">
                                    <DoneSharpIcon className="icon"/>
                                    <div>Base de données intégrées</div>
                                </div>
                                <div className="offre">
                                    <DoneSharpIcon className="icon"/>
                                    <div>Développement back end</div>
                                </div>
                                <div className="offre">
                                    <DoneSharpIcon className="icon"/>
                                    <div>Intégration 3D</div>
                                </div>
                                <div className="offre">
                                    <DoneSharpIcon className="icon"/>
                                    <div>Intégration API externe</div>
                                </div>
                                <div className="offre">
                                    <DoneSharpIcon className="icon"/>
                                    <div>+une fonctionnalité de votre choix</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="third-element">
                        <img src={faq} alt="faq" />
                        <div className="content">
                            <h2>Questions fréquemment posées</h2>
                            <div className="questions-content">
                                {
                                    questions.map((faq) => (
                                        <div key={faq.id}>
                                            <div className="questions">
                                                <span>{faq.questions}</span>
                                                <KeyboardArrowDownSharpIcon 
                                                    className="icon" 
                                                    onClick={() => handleResp(faq.id)}
                                                />
                                            </div>
                                            {displayResp.includes(faq.id) && <div className="reponse">{faq.reponses}</div>}
                                            <hr />
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="tel">
                    <div className="first-element">
                        <div className="top">
                            <span>Tarifs</span>
                            <p>Vous méritez d'avoir le meilleure accompagnement sans débourser 
                                une fortune, nous nous engageons à vous fournir un travail de qualité à prix abordable</p>
                        </div>
                        <div className="bottom">
                            <div className="box">
                                <div className="title">Basic</div>
                                <div className="price">
                                    <AttachMoneyIcon className="price"/>
                                </div>
                                <div className="desc">Pour les projets personnels</div>
                                <div className="offre">
                                    <DoneSharpIcon className="icon"/>
                                    <div>Logo si besoin </div>
                                </div>
                                <div className="offre">
                                    <DoneSharpIcon className="icon"/>
                                    <div>Design du site</div>
                                </div>
                                <div className="offre">
                                    <DoneSharpIcon className="icon"/>
                                    <div>Utilisation de CMS</div>
                                </div>
                                <div className="offre">
                                    <DoneSharpIcon className="icon"/>
                                    <div>5 pages maximum</div>
                                </div>
                                <div className="offre">
                                    <DoneSharpIcon className="icon"/>
                                    <div>Nom de domaine offert pour la première année</div>
                                </div>
                                <div className="offre">
                                    <DoneSharpIcon className="icon"/>
                                    <div>Hébergement offert pour le premier mois</div>
                                </div>
                                <div className="offre">
                                    <DoneSharpIcon className="icon"/>
                                    <div>1 mois de suivi gratuit après livraison</div>
                                </div>
                                <div className="offre">
                                    <DoneSharpIcon className="icon"/>
                                    <div>Interface administrateur</div>
                                </div>
                                <div className="offre">
                                    <ClearIcon className="icon2"/>
                                    <div>Intégration mode dark et light</div>
                                </div>
                                <div className="offre">
                                    <ClearIcon className="icon2"/>
                                    <div>Base de données intégrées</div>
                                </div>
                                <div className="offre">
                                    <ClearIcon className="icon2"/>
                                    <div>Développement back end</div>
                                </div>
                                <div className="offre">
                                    <ClearIcon className="icon2"/>
                                    <div>Intégration 3D</div>
                                </div>
                                <div className="offre">
                                    <ClearIcon className="icon2"/>
                                    <div>Interface administrateur</div>
                                </div>
                                <div className="offre">
                                    <ClearIcon className="icon2"/>
                                    <div>+une fonctionnalité de votre choix</div>
                                </div>
                                <Link to="/contact"><button>Demander un devis</button></Link>
                            </div>
                            <div className="box">
                                <div className="title">Pro</div>
                                <div className="price">
                                    <AttachMoneyIcon className="price"/>
                                    <AttachMoneyIcon className="price"/>
                                </div>
                                <div className="desc">Pour les petites et moyennes entreprises</div>
                                <div className="offre">
                                    <DoneSharpIcon className="icon"/>
                                    <div>Logo si besoin </div>
                                </div>
                                <div className="offre">
                                    <DoneSharpIcon className="icon"/>
                                    <div>Design du site</div>
                                </div>
                                <div className="offre">
                                    <DoneSharpIcon className="icon"/>
                                    <div>Developpement personnalisé</div>
                                </div>
                                <div className="offre">
                                    <DoneSharpIcon className="icon"/>
                                    <div>10 pages maximum</div>
                                </div>
                                <div className="offre">
                                    <DoneSharpIcon className="icon"/>
                                    <div>Nom de domaine offert pour la première année</div>
                                </div>
                                <div className="offre">
                                    <DoneSharpIcon className="icon"/>
                                    <div>Hébergement offert pour la première année</div>
                                </div>
                                <div className="offre">
                                    <DoneSharpIcon className="icon"/>
                                    <div>2 mois de suivi gratuit après livraison</div>
                                </div>
                                <div className="offre">
                                    <DoneSharpIcon className="icon"/>
                                    <div>Intégration mode dark et light</div>
                                </div>
                                <div className="offre">
                                    <DoneSharpIcon className="icon"/>
                                    <div>Base de données intégrées</div>
                                </div>
                                <div className="offre">
                                    <DoneSharpIcon className="icon"/>
                                    <div>Développement back end</div>
                                </div>
                                <div className="offre">
                                    <ClearIcon className="icon2"/>
                                    <div>Intégration 3D</div>
                                </div>
                                <div className="offre">
                                    <ClearIcon className="icon2"/>
                                    <div>Interface administrateur</div>
                                </div>
                                <div className="offre">
                                    <ClearIcon className="icon2"/>
                                    <div>+une fonctionnalité de votre choix</div>
                                </div>
                                <Link to="/contact"><button>Demander un devis</button></Link>
                            </div>
                            <div className="box">
                                <div className="title">Business</div>
                                <div className="price">
                                    <AttachMoneyIcon className="price"/>
                                    <AttachMoneyIcon className="price"/>
                                    <AttachMoneyIcon className="price"/>
                                </div>
                                <div className="desc">Pour les grandes entreprises</div>
                                <div className="offre">
                                    <DoneSharpIcon className="icon"/>
                                    <div>Logo si besoin </div>
                                </div>
                                <div className="offre">
                                    <DoneSharpIcon className="icon"/>
                                    <div>Design du site</div>
                                </div>
                                <div className="offre">
                                    <DoneSharpIcon className="icon"/>
                                    <div>Developpement personnalisé</div>
                                </div>
                                <div className="offre">
                                    <DoneSharpIcon className="icon"/>
                                    <div>20 pages maximum</div>
                                </div>
                                <div className="offre">
                                    <DoneSharpIcon className="icon"/>
                                    <div>Nom de domaine offert pour la première année</div>
                                </div>
                                <div className="offre">
                                    <DoneSharpIcon className="icon"/>
                                    <div>Hébergement offert pour la première année</div>
                                </div>
                                <div className="offre">
                                    <DoneSharpIcon className="icon"/>
                                    <div>3 mois de suivi gratuit après livraison</div>
                                </div>
                                <div className="offre">
                                    <DoneSharpIcon className="icon"/>
                                    <div>Intégration mode dark et light</div>
                                </div>
                                <div className="offre">
                                    <DoneSharpIcon className="icon"/>
                                    <div>Base de données intégrées</div>
                                </div>
                                <div className="offre">
                                    <DoneSharpIcon className="icon"/>
                                    <div>Développement back end</div>
                                </div>
                                <div className="offre">
                                    <DoneSharpIcon className="icon"/>
                                    <div>Intégration 3D</div>
                                </div>
                                <div className="offre">
                                    <DoneSharpIcon className="icon"/>
                                    <div>Interface administrateur</div>
                                </div>
                                <div className="offre">
                                    <DoneSharpIcon className="icon"/>
                                    <div>+une fonctionnalité de votre choix</div>
                                </div>
                                <Link to="/contact"><button>Demander un devis</button></Link>
                            </div>
                        </div>
                    </div>
                    <div className="third-element">
                        <img src={faq} alt="faq" />
                        <div className="content">
                            <h2>Questions fréquemment posées</h2>
                            <div className="questions-content">
                                {
                                    questions.map((faq) => (
                                        <div key={faq.id}>
                                            <div className="questions">
                                                <span>{faq.questions}</span>
                                                <KeyboardArrowDownSharpIcon 
                                                    className="icon" 
                                                    onClick={() => handleResp(faq.id)}
                                                />
                                            </div>
                                            {displayResp.includes(faq.id) && <div className="reponse">{faq.reponses}</div>}
                                            <hr />
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <NewsLetter />
                <Footer />
            </div>
        </React.Fragment>
    )
}

export default Tarifs;