import "./newsLetter.scss"
import React, { useState } from "react"

function NewsLetters() {
    const [formData, setFormData] = useState({
        email:""
    });
    const [message, setMessage] = useState("");
    const [error, setError] = useState("");
    const handleChange = (event) => {
        setFormData((prev) => ({
            ...prev, [event.target.name]:event.target.value
        }));
    }
    const handleSubmit = (event) => {
        event.preventDefault();
        const config = {
            SecureToken : "b7b04988-ad60-4bd8-af19-abca19c73d94",
            To : 'subscribe@dev-mastermind.com',
            From : "pezongomickael67@gmail.com",
            Subject : "New letters subscribe",
            Body : `Le mail ${formData.email} est souscrit aux news-letters`
        }
        if(window.Email){
            window.Email.send(config)
            .then(message => {
                if(message === "OK"){
                    setFormData({
                        email:"",
                    });
                    setMessage("success");
                }else{
                    setError("error");
                }
            })
            .catch(error => console.error(error));
        }
    }
    return(
        <div className="news-letters">
            <span>Subscribe to our newsletters</span>
            <form onSubmit={handleSubmit}>
                <input 
                    type="email" 
                    name="email"
                    placeholder="Enter your email"
                    onChange={handleChange}
                    value={formData.email}
                    required
                    
                />
                <input type="submit" value="Subscribe"/>
                <button>Subscribe</button>
            </form>
            {
                message
                    ?
                        <div className="success_message">
                            Message sent successfully
                        </div>
                    :null
            }
            {
                error
                    ?
                        <div className="error_message">
                            An error occurred, please try again
                        </div>
                    :null
            }
        </div>
        )
}

export default NewsLetters;