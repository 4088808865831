import React, { useState } from "react"
import Map from "./map"
// import blob6 from "../../../../assets/blob6.jpg" 
import contact from "../../../assets/contact2.png" 
import Navbar from "../../../component/navbar/Navbar_en"
import NewsLetter from "../../../component/news-letter/NewsLetter_en"
import Footer from "../../../component/footer/Footer_en"
import "../../fr/contact/contact.scss"

function ContactEn() {
    const [formData, setFormData] = useState({
        nomPrenom:"",
        email:"",
        tel:"",
        message:"",
        subject:""
    });
    const [message, setMessage] = useState("");
    const [error, setError] = useState("");
    const handleChange = (event) => {
        setFormData((prev) => ({
            ...prev, [event.target.name]:event.target.value
        }));
    }
    const handleSubmit = (event) => {
        event.preventDefault();
        const config = {
            SecureToken : "b7b04988-ad60-4bd8-af19-abca19c73d94",
            To : 'contact@dev-mastermind.com',
            From : "pezongomickael67@gmail.com",
            Subject : formData.subject,
            Body : `Mr ${formData.nomPrenom}<br>
                    Email: ${formData.email}<br>
                    Telephone:${formData.tel}<br>
                    Message:${formData.message}`
        }
        if(window.Email){
            window.Email.send(config)
            .then(message => {
                if(message === "OK"){
                    setFormData({
                        nomPrenom:"",
                        email:"",
                        tel:"",
                        message:"",
                        subject:""
                    });
                    setMessage("success");
                }else{
                    setError("error");
                }
            })
            .catch(error => console.error(error));
        }
    }
    return(
        <React.Fragment>
            <Navbar page = "contact"/>
            <div className="formulaire">
                <div className="left">
                    <img src={contact} alt="" />
                </div>
                <div className="right">
                    <span>Contact us</span>
                    <p>Explore the digital world with us</p>
                    <form onSubmit={handleSubmit}>
                        <label htmlFor="nomPrenom">First-Name & Last-Prénom</label>
                        <input 
                            type="text" 
                            placeholder="First-Name & Last-name" 
                            id="nomPrenom" 
                            name="nomPrenom" 
                            className="nomPrenom"
                            onChange={handleChange}
                            value={formData.nomPrenom}
                            required
                        />
                        <label htmlFor="email">Email</label>
                        <input 
                            type="email" 
                            placeholder="Email address" 
                            id="email" name="email" 
                            className="email"
                            onChange={handleChange}
                            value={formData.email}
                            required
                        />
                        <label htmlFor="tel">Tel</label>
                        <input 
                            type="tel" 
                            placeholder="Phone number" 
                            id="tel" 
                            name="tel" 
                            className="telephone"
                            onChange={handleChange}
                            value={formData.tel}
                            required
                        />
                        <label htmlFor="subject">Sujet</label>
                        <input 
                            type="text" 
                            placeholder="Sujet" 
                            id="subject" 
                            name="subject" 
                            className="telephone"
                            value={formData.subject}
                            required
                            onChange={handleChange}
                        />
                        <label htmlFor="message">Message</label>
                        <textarea 
                            name="message" 
                            id="message" 
                            placeholder="What do you want from us?"
                            onChange={handleChange}
                            value={formData.message}
                            required
                        ></textarea>
                        <input type="submit" value="Send"/>
                    </form>
                    {
                        message
                            ?
                                <div className="success_message">
                                    Message sent successfully
                                </div>
                            :null
                    }
                    {
                        error
                            ?
                                <div className="error_message">
                                    An error occurred, please try again
                                </div>
                            :null
                    }
                </div>
            </div>
            <div className="horaire">
                <div className="top">
                    <span>Taking an appointment</span>
                    <p>
                        Discuss with our experts directly in our offices to learn 
                        more about our company.
                    </p>
                </div>
                <div className="bottom">
                    <table border="1" cellSpacing="0" align="center">
                        <tbody>
                            <tr className="first-line">
                                <th className="day">Horaire</th>
                                <th>8h-10h</th>
                                <th>10h-12h</th>
                                <th>12h-14h</th>
                                <th>14h-16h</th>
                                <th>16h-18h</th>
                            </tr>
                            <tr>
                                <td className="day">Lundi</td>
                                <td className="indisponible">Unavailable</td>
                                <td className="disponible">Take this time slot</td>
                                <td className="disponible">Take this time slot</td>
                                <td className="disponible">Take this time slot</td>
                                <td className="disponible">Take this time slot</td>
                            </tr>
                            <tr>
                                <td className="day">Mardi</td>
                                <td className="disponible">Take this time slot</td>
                                <td className="disponible">Take this time slot</td>
                                <td className="disponible">Take this time slot</td>
                                <td className="disponible">Take this time slot</td>
                                <td className="disponible">Take this time slot</td>
                            </tr>
                            <tr>
                                <td className="day">Mercredi</td>
                                <td className="indisponible">Unavailable</td>
                                <td className="indisponible">Unavailable</td>
                                <td className="indisponible">Unavailable</td>
                                <td className="indisponible">Unavailable</td>
                                <td className="indisponible">Unavailable</td>
                            </tr>
                            <tr>
                                <td className="day">Jeudi</td>
                                <td className="indisponible">Unavailable</td>
                                <td className="indisponible">Unavailable</td>
                                <td className="disponible">Take this time slot</td>
                                <td className="disponible">Take this time slot</td>
                                <td className="disponible">Take this time slot</td>
                            </tr>
                            <tr>
                                <td className="day">Vendredi</td>
                                <td className="indisponible">Unavailable</td>
                                <td className="disponible">Take this time slot</td>
                                <td className="disponible">Take this time slot</td>
                                <td className="indisponible">Unavailable</td>
                                <td className="disponible">Take this time slot</td>
                            </tr>
                            <tr>
                                <td className="day">Samedi</td>
                                <td className="disponible">Take this time slot</td>
                                <td className="disponible">Take this time slot</td>
                                <td className="indisponible">Unavailable</td>
                                <td className="indisponible">Unavailable</td>
                                <td className="indisponible">Unavailable</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            
            <div className="map">
                <hr /> 
                <Map />
            </div>
            <NewsLetter />
            <Footer />
        </React.Fragment>
    )
}

export default ContactEn;