import StarIcon from '@mui/icons-material/Star';
// import StarHalfIcon from '@mui/icons-material/StarHalf';
// import StarOutlineIcon from '@mui/icons-material/StarOutline';
import fijao from "../../assets/logo_fijao.png"
import lithyus from '../../assets/Logo_lithyusmusic3.png'
// import profile from "../../assets/blank_profile.png"
import "./temoignages.scss"

function Temoignages() {
    return(
        <div className="height-element">
                    <div className="top">
                        <h2>Témoignages</h2>
                        <p>
                            Les gens aiment ce que nous faisons et nous voulons vous le faire savoir
                        </p>
                    </div>
                    <div className="bottom">
                        <div className="box">
                            <p style={{fontSize:'12px'}}>
                                "Nous exprimons notre profonde gratitude à DevMasterMind pour leur travail exceptionnel, leur 
                                dévouement et leur détermination lors de l'organisation du FIJAO 2023. Leur contribution inestimable a 
                                grandement contribué à notre succès. Leur expertise technique, leur créativité et leur 
                                professionnalisme ont été remarquables. Merci d'avoir été un partenaire de confiance et d'avoir été à 
                                nos côtés tout au long de cette aventure."
                            </p>
                            <div className="star">
                                <StarIcon className="start-icon"/>
                                <StarIcon className="start-icon"/>
                                <StarIcon className="start-icon"/>
                                <StarIcon className="start-icon"/>
                                <StarIcon className="start-icon"/>
                            </div>
                            <div className="info-perso">
                                <img src={fijao} alt="logo fijao" style={{width:'200px', height:'200px'}}/>
                                <h3>L'équipe du FIJAO</h3>
                                <span>Président de l'association</span>
                            </div>
                        </div>
                        <div className="box">
                            <p style={{fontSize:'12px'}}>
                                "Franchement, DevMasterMind vous êtes efficaces dans la réalisation des projets qui vous sont assignés 
                                et vous tenez vos délais, vos services sont vraiment parfaits! Merci de nous aidez dans le developpement la mise en 
                                ligne de notre plate-forme de distribution de musique!"
                            </p>
                            <div className="star">
                                <StarIcon className="start-icon"/>
                                <StarIcon className="start-icon"/>
                                <StarIcon className="start-icon"/>
                                <StarIcon className="start-icon"/>
                                <StarIcon className="start-icon"/>
                            </div>
                            <div className="info-perso">
                                <div className="img_temoig">

                                <img src={lithyus} alt="logo de lithyus Music" style={{width:'100px', height:'100px'}}/>
                                </div>
                                <h3>L'équipe du FIJAO</h3>
                                <span>PDG de Lithyus Music</span>
                            </div>
                        </div>
                        <div className="box">
                            <p style={{fontSize:'12px'}}>
                                "Nous exprimons notre profonde gratitude à DevMasterMind pour leur travail exceptionnel, leur 
                                dévouement et leur détermination lors de l'organisation du FIJAO 2023. Leur contribution inestimable a 
                                grandement contribué à notre succès. Leur expertise technique, leur créativité et leur 
                                professionnalisme ont été remarquables. Merci d'avoir été un partenaire de confiance et d'avoir été à 
                                nos côtés tout au long de cette aventure."
                            </p>
                            <div className="star">
                                <StarIcon className="start-icon"/>
                                <StarIcon className="start-icon"/>
                                <StarIcon className="start-icon"/>
                                <StarIcon className="start-icon"/>
                                <StarIcon className="start-icon"/>
                            </div>
                            <div className="info-perso">
                                <img src={fijao} alt="logo fijao" style={{width:'200px', height:'200px'}}/>
                                <h3>L'équipe du FIJAO</h3>
                                <span>Président de l'association</span>
                            </div>
                        </div>
                    </div>
        </div>
    )
}

export default Temoignages;