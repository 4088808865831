import { Link } from "react-router-dom"
import { useState, useContext } from 'react';
import React from "react"
import NightlightIcon from '@mui/icons-material/Nightlight';
import WbSunnyOutlinedIcon from '@mui/icons-material/WbSunnyOutlined';
import { DarkModeContext, LangueContext } from "../../context/modeContext";
import logo from "../../assets/DM2.png"
import Footer from "../footer/Footer_en"
import ang from "../../assets/drapeau_en.png"
import "./navbar.scss"

function Navbar({page}){
    const [burger_class, setBurgerClass] = useState("burger-bar unclicked")
    const [menu_class, setMenuClass] = useState("menu hidden")
    const [isMenuClicked, setIsMenuClicked] = useState(false)
    

    const { toggle, darkMode } = useContext(DarkModeContext);
    const { toggle_lang } = useContext(LangueContext);
    const updateMenu = () => {
        if(!isMenuClicked) {
            setBurgerClass("burger-bar clicked")
            setMenuClass("menu visible")
        }
        else {
            setBurgerClass("burger-bar unclicked")
            setMenuClass("menu hidden")
        }
        setIsMenuClicked(!isMenuClicked)
    }
    return(
            <React.Fragment>
                <div>
                    <div  className="navbar-tel">
                        <nav>
                            <div className="burger-menu" onClick={updateMenu}>
                                <div className={burger_class} ></div>
                                <div className={burger_class} ></div>
                                <div className={burger_class} ></div>
                            </div>
                            <div className="right">
                                {darkMode
                                    ? <NightlightIcon onClick={toggle} className = "icon"/>
                                    : <WbSunnyOutlinedIcon onClick={toggle} className = "icon"/>
                                }
                                <div className="img" onClick={toggle_lang}>
                                    <img src={ang} alt="Anglais" />
                                </div>   
                            </div>
                        </nav>
                        <div className={menu_class}>
                            <div className="middle">
                                <div>
                                    <Link to="/" className="middle-content">
                                        {
                                            page === "home"
                                            ? <span style={{color:"#3164F4"}}>Home</span>
                                            :<span>Home</span>
                                        }
                                    </Link>
                                </div>
                                <hr />
                                <div>
                                    <Link to="/tarifs" className="middle-content">
                                        {
                                            page === "tarifs"
                                            ? <span style={{color:"#3164F4"}}>Prices</span>
                                            :<span>Prices</span>
                                        }
                                    </Link>
                                </div>
                                <hr />
                                <div>
                                    <Link to="/about-us" className="middle-content">
                                        { 
                                            page === "propos"
                                            ? <span style={{color:"#3164F4"}}>About us</span>
                                            :<span>About us</span>
                                        }
                                    </Link>
                                </div>
                                <hr />
                                <div>
                                    <Link to="/contact" className="middle-content">
                                        {
                                            page === "contact"
                                            ? <span style={{color:"#3164F4"}}>Contact</span>
                                            :<span>Contact</span>
                                        }
                                    </Link>
                                </div>
                                <hr />
                                <div>
                                    <Link to="/partner" className="middle-content">
                                        {
                                            page === "partner"
                                            ? <span style={{color:"#3164F4"}}>Partner</span>
                                            :<span>Partner</span>
                                        }
                                    </Link>
                                </div>
                                <hr />
                            </div>
                            <div className="time">
                                <div className="img" onClick={toggle_lang}>
                                    <img src={ang} alt="Anglais" />
                                    <span> English</span>
                                    <Footer />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="navbar">
                        <div className="left">
                            <div className="logo">
                            <Link to="/" className="left-content"><img src={logo} alt="logo" /></Link> 
                            </div>
                        </div>
                        <div className="middle">
                            <div>
                                <Link to="/" className="middle-content">
                                    {
                                        page === "home"
                                        ? <span style={{color:"#3164F4"}}>Home</span>
                                        :<span>Home</span>
                                    }
                                </Link>
                            </div>
                            <div>
                                <Link to="/tarifs" className="middle-content">
                                    {
                                        page === "tarifs"
                                        ? <span style={{color:"#3164F4"}}>Prices</span>
                                        :<span>Prices</span>
                                    }
                                </Link>
                            </div>
                            <div>
                                <Link to="/about-us" className="middle-content">
                                    { 
                                        page === "propos"
                                        ? <span style={{color:"#3164F4"}}>About us</span>
                                        :<span>About us</span>
                                    }
                                </Link>
                            </div>
                            <div>
                                <Link to="/contact" className="middle-content">
                                    {
                                        page === "contact"
                                        ? <span style={{color:"#3164F4"}}>Contact</span>
                                        :<span>Contact</span>
                                    }
                                </Link>
                            </div>
                            <div>
                                <Link to="/partner" className="middle-content">
                                    {
                                        page === "partner"
                                        ? <span style={{color:"#3164F4"}}>Partner</span>
                                        :<span>Partner</span>
                                    }
                                </Link>
                            </div>
                        </div>
                        <div className="right" >
                            <div className="mail">contact@dev-mastermind.com</div>
                            {darkMode
                                ? <NightlightIcon onClick={toggle} className = "icon"/>
                                : <WbSunnyOutlinedIcon onClick={toggle} className = "icon"/>
                            }
                            <div className="time">
                                {/* <AccessTimeIcon />
                                <h3>
                                    {date.toLocaleTimeString(
                                        [], 
                                        {   
                                            hour: '2-digit', 
                                            minute: '2-digit', 
                                            second: '2-digit', 
                                            hour12: false 
                                        }
                                    )}
                                </h3> */}
                                <div className="img" onClick={toggle_lang}>
                                    <img src={ang} alt="Anglais" />
                                    <span> English</span>
                                </div>
                            </div>
                        </div>
                    </div> 
                </div>
            </React.Fragment>
    )
}

export default Navbar;