import React from "react"
import { Link } from "react-router-dom"
import Navbar from "../../../component/navbar/Navbar"
import Footer from "../../../component/footer/Footer"
import "./conditions.scss"

function Conditions() {
    return(
        <div className="conditions">
            <Navbar />
            <div className="corps">
                <span>COMING SOON</span>
                <hr />
                <p>
                    Nous travaillons actuellement pour mettre au point des conditions d'utilisations simples et faciles 
                    à prendre en main. Contacter nous pour en savoir plus
                </p>
                <Link to="/contact"><button>Contacter</button></Link>
            </div>
            <Footer />
        </div>

    )
}

export default Conditions;