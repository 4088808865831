import React from "react";
import { Link } from "react-router-dom"
import Navbar from "../../../component/navbar/Navbar";
import propos from "../../../assets/DM2.png";
import mick from "../../../assets/profile.jpeg";
import yasco from "../../../assets/yasco.jpg";
import rachid from "../../../assets/rachid.jpg";
import adamou from "../../../assets/Adamou.jpg";
import junior from "../../../assets/junior.JPG";
import kam from "../../../assets/kam.jpg";
import Josue from "../../../assets/josue ouedraogo.png";
import aziz from "../../../assets/abdoul.jpg";
import sami from "../../../assets/sami.jpeg";
import aude from "../../../assets/aude.jpg"
import bg1 from "../../../assets/group.png"
import "./propos.scss";
import Footer from "../../../component/footer/Footer";
import NewsLetters from "../../../component/news-letter/NewsLetter";
import Temoignages from "../../../component/temoignages/Temoignages";
import FacebookTwoToneIcon from '@mui/icons-material/FacebookTwoTone';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

function Propos() {
  return (
    <React.Fragment>
      <div>
        <Navbar page="propos" />
              <div className="propos">
                <div className="first-element">
                  <div className="box1" style={{backgroundImage:`url(${bg1})`, backgroundSize:'cover'}}>
                                        
                  </div>
                  <div className="box">
                    <span>Qui sommes-nous ?</span>
                    <p>
                      DevMasterMind est une entreprise de pointe spécialisée dans le
                      développement d'applications web et mobiles, ainsi que dans la
                      gestion et la maintenance d'applications pour aider les entreprises
                      à atteindre leurs objectifs numériques. Nous sommes une équipe de
                      professionnels talentueux et passionnés, prêts à mettre nos
                      compétences au service de votre entreprise. Nous sommes fiers de
                      proposer des solutions innovantes et personnalisées pour répondre à
                      vos besoins spécifiques. Avec DevMasterMind, vous pouvez vous
                      attendre à une qualité de service exceptionnelle, une communication
                      transparente et un engagement envers l'excellence. Nous sommes
                      déterminés à aider nos clients à tirer le meilleur parti de leur
                      présence en ligne grâce à des applications web et mobiles
                      performantes, fiables et faciles à utiliser. Nous sommes convaincus
                      que notre expertise en développement d'applications combinée à notre
                      connaissance approfondie des tendances et des technologies
                      émergentes vous permettra de vous démarquer dans un marché en
                      constante évolution.Choisir DevMasterMind, c'est choisir une
                      entreprise de confiance pour vous aider à réaliser vos objectifs
                      numériques avec succès. Nous sommes impatients de travailler avec
                      vous et de faire de votre vision une réalité.
                    </p>
                
                    <img src={propos} alt="" />
                    <span>Notre mission</span>
                    <p className="p2">
                      Notre mission est de vous fournir des solutions de développement
                      d'applications web et mobiles de haute qualité pour aider votre
                      entreprise à atteindre ses objectifs numériques. Nous offrons
                      également des services de gestion et de maintenance d'applications
                      pour assurer une performance optimale et une fiabilité à long terme.
                      L'objectif est de fournir des solutions innovantes et adaptées pour
                      aider les clients à améliorer leur présence en ligne et à stimuler
                      leur croissance. Aussi DevMasterMind se veut de rester à la pointe des
                      tendances et des technologies émergentes dans le domaine du
                      développement d'applications pour garantir que les solutions proposées
                      restent pertinentes et compétitives.
                    </p>
                  </div>
                </div>
                {/* <div className="second-element">
                  <div className="content">
                    <div className="left">
                      <h1>Commençons à travailler plus efficacement dès aujourd'hui !</h1>
                    </div>
                    <div className="right">
                      <img className="img2" src={blob1} alt="blob" />
                    </div>
                  </div>
                </div> */}

                <div className="third-element">
                    <h2>Notre équipe</h2>
                    <p>Rencontrez les personnes derrières notre structure</p>
                  <div className="team">
                    <div className="box">
                      <div className="info-perso">
                        <img src={mick} alt="profile" />
                        <h3>PEZONGO Mickael</h3>
                        <div className="poste">Fondateur&Developpeur</div>
                        <div className="socila-network">
                        <Link target="_blank" rel="noopener noreferrer" to="https://web.facebook.com/mickael.pezongo"><FacebookTwoToneIcon className="facebook"/></Link>
                          <Link target="_blank" rel="noopener noreferrer" to="https://twitter.com/PezongoMickael"><TwitterIcon className="twitter"/></Link>
                          <Link target="_blank" rel="noopener noreferrer" to="https://www.linkedin.com/in/micka%C3%ABl-pezongo-90b814226/"><LinkedInIcon className="linkedin"/></Link>
                        </div>
                      </div>
                    </div>
                    <div className="box">
                      <div className="info-perso">
                        <img src={Josue} alt="profile_Franck_Alain" />
                        <h3>OUEDRAOGO Josue</h3>
                        <div className="poste">Partenaire&Developpeur</div>
                        <div className="socila-network">
                          <Link target="_blank" rel="noopener noreferrer" to="https://www.facebook.com/franki.ouangraoua?mibextid=LQQJ4d" ><FacebookTwoToneIcon className="facebook"/></Link>
                          <Link target="_blank" rel="noopener noreferrer" to="https://twitter.com/wangfranck5" ><TwitterIcon className="twitter"/></Link>
                          <Link target="_blank" rel="noopener noreferrer" to="https://www.linkedin.com/in/franck-alain-ouangraoua-91091b1a9" ><LinkedInIcon className="linkedin"/></Link>
                        </div>
                      </div>
                    </div>
                    <div className="box">
                      <div className="info-perso">
                        <img src={adamou} alt="profile_Adamou" />
                        <h3>DIMA  Adamou</h3>
                        <div className="poste">Partenaire&Developpeur</div>
                        <div className="socila-network">
                          <Link target="_blank" rel="noopener noreferrer" to="https://web.facebook.com/adamou.dima/" ><FacebookTwoToneIcon className="facebook"/></Link>
                          <Link target="_blank" rel="noopener noreferrer" to="https://twitter.com/adamoudima" ><TwitterIcon className="twitter"/></Link>
                          <Link target="_blank" rel="noopener noreferrer" to="https://www.linkedin.com/in/adamou-dima-912785160/" ><LinkedInIcon className="linkedin"/></Link>
                        </div>
                      </div>
                    </div>
                    <div className="box">
                      <div className="info-perso">
                        <img src={aziz} alt="profile_abdoul_aziz" />
                      <h3>TRAORE Abdoul Aziz</h3>
                        <div className="poste">Partenaire&Developpeur</div>
                        <div className="socila-network">
                          <Link target="_blank" rel="noopener noreferrer" to="https://www.facebook.com/abdoulaziz.traore.3910" ><FacebookTwoToneIcon className="facebook"/></Link>
                          <Link target="_blank" rel="noopener noreferrer" to="https://twitter.com/TraoreAAziz1?t=UpH1FhoeniaCnTQOXaGP3Q&s=09" ><TwitterIcon className="twitter"/></Link>
                          <Link target="_blank" rel="noopener noreferrer" to="https://www.linkedin.com/in/abdoul-aziz-traore-615939201/" ><LinkedInIcon className="linkedin"/></Link>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="team">
                  <div className="box">
                      <div className="info-perso">
                        <img src={yasco} alt="profile_Yascinthe" />
                        <h3>ILBOUDO Bénéwendé Yacinthe</h3>
                        <div className="poste">Partenaire&Designer</div>
                        <div className="socila-network">
                          <Link target="_blank" rel="noopener noreferrer" to="https://www.facebook.com/profile.php?id=100091939973530" ><FacebookTwoToneIcon className="facebook"/></Link>
                          <Link target="_blank" rel="noopener noreferrer" to="https://twitter.com/YacintheIL66787"><TwitterIcon className="twitter"/></Link>
                          <Link target="_blank" rel="noopener noreferrer" to="https://www.linkedin.com/in/b%C3%A9n%C3%A9wend%C3%A9-yacinthe-ilboudo-963708230/"><LinkedInIcon className="linkedin"/></Link>
                        </div>
                      </div>
                    </div>
                    <div className="box">
                      <div className="info-perso">
                        <img src={sami} alt="profile_triple_S" />
                        <h3>SOU Sami Samuel</h3>
                        <div className="poste">Partenaire&Designer</div>
                        <div className="socila-network">
                          <Link target="_blank" rel="noopener noreferrer" to="https://www.facebook.com/profile.php?id=100057758568256&mibextid=LQQJ4d"><FacebookTwoToneIcon className="facebook"/></Link>
                          <Link target="_blank" rel="noopener noreferrer" to="https://twitter.com/sami_samuel_"><TwitterIcon className="twitter"/></Link>
                          <Link target="_blank" rel="noopener noreferrer" to="https://www.linkedin.com/in/sami-samuel-sou-97723826b"><LinkedInIcon className="linkedin"/></Link>
                        </div>
                      </div>
                    </div>
                    <div className="box">
                      <div className="info-perso">
                        <img src={kam} alt="profile_Kam" />
                        <h3>KAM Franck Levie</h3>
                        <div className="poste">PArtenaire&Designer</div>
                        <div className="socila-network">
                          <Link target="_blank" rel="noopener noreferrer" to="https://www.facebook.com/levy.kam.9?mibextid=ZbWKwL"><FacebookTwoToneIcon className="facebook"/></Link>
                          <Link target="_blank" rel="noopener noreferrer" to="https://twitter.com/Franck35715945?t=J1VE8RflAEtcuWNMYDeDtA&s=09"><TwitterIcon className="twitter"/></Link>
                          <Link target="_blank" rel="noopener noreferrer" to="https://www.linkedin.com/in/franck-levie-kam"><LinkedInIcon className="linkedin"/></Link>
                        </div>
                      </div>
                    </div>
                    <div className="box">
                      <div className="info-perso">
                        <img src={junior} alt="profile_junior" />
                        <h3>NIKIEMA Moussa W Edmond Junior</h3>
                        <div className="poste">Partenaire&Designer</div>
                        <div className="socila-network">
                          <Link target="_blank" rel="noopener noreferrer" to="https://www.facebook.com/junior.nikiema.9" ><FacebookTwoToneIcon className="facebook"/></Link>
                          <Link target="_blank" rel="noopener noreferrer" to="https://twitter.com/Nikiemajunior22?t=ENhWGkgfH3wo-hF8GGvAnA&s=09" ><TwitterIcon className="twitter"/></Link>
                          <Link target="_blank" rel="noopener noreferrer" to="https://www.linkedin.com/in/junior-nikiema-379197259" ><LinkedInIcon className="linkedin"/></Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="team">
                    <div className="box">
                        <div className="info-perso">
                          <img src={rachid} alt="profile" />
                          <h3>NASSA Abdoul Rachid</h3>
                          <div className="poste">Partenaire&Responsable Marketing</div>
                          <div className="socila-network">
                            <Link target="_blank" rel="noopener noreferrer" to="https://www.facebook.com/abdoulrachid.nassa?mibextid=LQQJ4d"><FacebookTwoToneIcon className="facebook"/></Link>
                            <Link target="_blank" rel="noopener noreferrer" to="https://twitter.com/rachidnassa/"><TwitterIcon className="twitter"/></Link>
                            <Link target="_blank" rel="noopener noreferrer" to="https://www.linkedin.com/in/abdoul-rachid-nassa-b6927a19a"><LinkedInIcon className="linkedin"/></Link>
                          </div>
                        </div>
                      </div>
                      <div className="box">
                        <div className="info-perso">
                          <img src={aude} alt="profile_aude" />
                          <h3>AGNIMEL Aude Leatitia</h3>
                          <div className="poste">Partenaire & Responsable en communication</div>
                          <div className="socila-network">
                            <Link target="_blank" rel="noopener noreferrer" to="https://www.facebook.com/audeagnimel?mibextid=LQQJ4d" ><FacebookTwoToneIcon className="facebook"/></Link>
                            <Link target="_blank" rel="noopener noreferrer" to="https://twitter.com/agnimel_aude" ><TwitterIcon className="twitter"/></Link>
                            <Link target="_blank" rel="noopener noreferrer" to="https://www.linkedin.com/in/aude-leatitia-agnimel-communication-marketing" ><LinkedInIcon className="linkedin"/></Link>
                          </div>
                        </div>
                      </div>
                  </div>
                </div>
                <div className="telPropos">
                  <div className="fourth-element">
                    <div className="content">
                        <div className="left">
                        <h1>Commencer avec DMM dès maintenant</h1>
                        <img src={propos} alt="lando"/>
                        <p>Commencez à optimiser votre entreprise dès aujourd'hui.</p>
                        <Link to="/contact"><button>Demander un devis</button></Link>
                        </div>
                    </div>
                  </div>
                </div>
                <div className="ordinateurPropos">
                    <div className="fourth-element">
                      <div className="content">
                        <div className="left">
                          <h1>Commencer avec DMM dès maintenant</h1>
                          <p>Commencez à optimiser votre entreprise dès aujourd'hui.</p>
                          <Link to="/contact"><button>Demander un devis</button></Link>
                        </div>
                        <div className="right">
                          <img src={propos} alt="blob" />
                        </div>
                      </div>
                    </div>
                </div>
                </div>


              <Temoignages />
              <NewsLetters />
              <Footer />
      </div>
    </React.Fragment>
  );
}
export default Propos;
