import React from "react"
import { Link } from "react-router-dom"
import Navbar from "../../../component/navbar/Navbar_en"
import Footer from "../../../component/footer/Footer_en"
import "../../fr/conditions/conditions.scss"

function Conditions() {
    return(
        <div className="conditions">
            <Navbar />
            <div className="corps">
                <span>COMING SOON</span>
                <hr />
                <p>
                    We are currently working to develop simple and easy-to-use terms of use. Contact us to learn more.
                </p>
                <Link to="/contact"><button>Contact-us</button></Link>
            </div>
            <Footer />
        </div>

    )
}

export default Conditions;