import React from "react"
import { Link } from "react-router-dom"
import { Fade, Slide } from 'react-slideshow-image'
import 'react-slideshow-image/dist/styles.css'
import lando from "../../../assets/DM2.png"
import Navbar from "../../../component/navbar/Navbar_en"
import Footer from "../../../component/footer/Footer_en"
import Temoignages from "../../../component/temoignages/Temoignages_en"
import NewsLetter from "../../../component/news-letter/NewsLetter_en"
import blob from "../../../assets/idea.png"
import blob1 from "../../../assets/2.png"
import blob2 from "../../../assets/4.png"
import bp from "../../../assets/logo_partenaire.png"
import logo_lithyusmusic from "../../../assets/Logo_lithyusmusic3.png"
import fijao from "../../../assets/logo_fijao.png"
// import blob5 from "../../../assets/blob5.png"
import blob3 from "../../../assets/taget.png"
import dev from "../../../assets/dev.png"
import webDesign from "../../../assets/web.png"
import digital from "../../../assets/marketing-digital.png"
import rs from "../../../assets/social-network.png"
import seo from "../../../assets/seo.png"
import ecommerce from "../../../assets/e-commerce.png"
import bg1 from "../../../assets/colleagues-giving-fist-bump.png"
import bg2 from "../../../assets/still.png"
import bg3 from "../../../assets/web.png"
import bg4 from "../../../assets/bg4.png"
import fiftyBg from "../../../assets/genial.png"
import apero from '../../../assets/apero.png'
import "../../fr/home/home.scss"

function HomeEn(){
    const settings = {
        duration: 5000, // durée de transition en millisecondes
        transitionDuration: 1000,
        infinite: true,
        indicators: true,
        arrows: true,
    };    
    return(

        <React.Fragment>
            <div className="home">
                <Navbar page = "home"/>
                    <div className="tel">
                        <Fade 
                            {...settings}
                            prevArrow={
                                <div 
                                    style={{
                                                fontSize: '0px',
                                                backgroundColor:'white',
                                                height: '70px',
                                                width: '70px',
                                                opacity: '0.5',
                                                borderRadius: '50%',
                                                position:'absolute',
                                                paddingTop: '10px',
                                                paddingLeft: '10px'
                                            }}
                                >{<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 30 24" strokeWidth={2} stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M18.75 19.5l-7.5-7.5 7.5-7.5m-6 15L5.25 12l7.5-7.5" />
                            </svg>
                            }</div>}
                            nextArrow={
                                <div 
                                style={{
                                    fontSize: '0px',
                                    backgroundColor:'white',
                                    opacity: '0.5',
                                    height: '70px',
                                    width: '70px',
                                    borderRadius: '50%',
                                    position:'absolute',
                                    paddingTop: '10px',
                                    paddingLeft: '10px'
                                }}
                                
                                >{<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 30 24" strokeWidth={2} stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M11.25 4.5l7.5 7.5-7.5 7.5m-6-15l7.5 7.5-7.5 7.5" />
                            </svg>
                            }</div>}
                        >
                            <div className="first-element">
                                <div className="box1" style={{backgroundImage:`url(${bg1})`, backgroundSize:'cover'}}>
                                    
                                </div>
                                <div className="box">
                                    <div className="left">
                                        <div className="left-content">
                                            <span className="free-quote">- FREE QUOTE</span>
                                            <h2>The best way to digitize your business</h2>
                                            <p>
                                                DevMasterMind is a cutting-edge company specialized 
                                                in the development of web and mobile applications, as well as 
                                                in the management and maintenance of applications to help businesses 
                                                achieve their digital objectives
                                            </p>
                                            <div className="btns">
                                                <div>
                                                    <Link to="/contact"><button className="devi">Request a quote</button></Link>
                                                </div>
                                                <div>
                                                    <Link to="/tarifs"><button className="see-more">View our offers</button></Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="first-element">
                                <div className="box1" style={{backgroundImage:`url(${bg2})`, backgroundSize:'cover'}}>
                                    
                                </div>
                                <div className="box">
                                    <div className="left">
                                        <div className="left-content">
                                            <span className="free-quote">- FREE QUOTE</span>
                                            <h2>The best way to digitize your business</h2>
                                            <p>
                                                DevMasterMind is a cutting-edge company specialized 
                                                in the development of web and mobile applications, as well as 
                                                in the management and maintenance of applications to help businesses 
                                                achieve their digital objectives
                                            </p>
                                            <div className="btns">
                                                <div>
                                                    <Link to="/contact"><button className="devi">Request a quote</button></Link>
                                                </div>
                                                <div>
                                                    <Link to="/tarifs"><button className="see-more">View our offers</button></Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="first-element">
                                <div className="box1" style={{backgroundImage:`url(${bg3})`, backgroundSize:'cover'}}>
                                    
                                </div>
                                <div className="box">
                                    <div className="left">
                                        <div className="left-content">
                                            <span className="free-quote">- FREE QUOTE</span>
                                            <h2>The best way to digitize your business</h2>
                                            <p>
                                                DevMasterMind is a cutting-edge company specialized 
                                                in the development of web and mobile applications, as well as 
                                                in the management and maintenance of applications to help businesses 
                                                achieve their digital objectives
                                            </p>
                                            <div className="btns">
                                                <div>
                                                    <Link to="/contact"><button className="devi">Request a quote</button></Link>
                                                </div>
                                                <div>
                                                    <Link to="/tarifs"><button className="see-more">View our offers</button></Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="first-element">
                                <div className="box1" style={{backgroundImage:`url(${bg4})`, backgroundSize:'cover'}}>
                                    
                                </div>
                                <div className="box">
                                    <div className="left">
                                        <div className="left-content">
                                            <span className="free-quote">- FREE QUOTE</span>
                                            <h2>The best way to digitize your business</h2>
                                            <p>
                                                DevMasterMind is a cutting-edge company specialized 
                                                in the development of web and mobile applications, as well as 
                                                in the management and maintenance of applications to help businesses 
                                                achieve their digital objectives
                                            </p>
                                            <div className="btns">
                                                <div>
                                                    <Link to="/contact"><button className="devi">Request a quote</button></Link>
                                                </div>
                                                <div>
                                                    <Link to="/tarifs"><button className="see-more">View our offers</button></Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Fade>
                        <div className="second-element">
                            <div className="title">
                                Several companies trust us
                            </div>
                            <div className="client">
                                <Slide 
                                    prevArrow={
                                        <div 
                                            style={{
                                                        fontSize: '0px',
                                                        backgroundColor:'white',
                                                        height: '70px',
                                                        width: '90px',
                                                        borderRadius: '50%',
                                                        position:'relative',
                                                        paddingTop: '10px',
                                                        paddingLeft: '10px'
                                                    }}
                                        >{<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 30 24" strokeWidth={2} stroke="currentColor" className="w-6 h-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M18.75 19.5l-7.5-7.5 7.5-7.5m-6 15L5.25 12l7.5-7.5" />
                                    </svg>
                                    }</div>}
                                    nextArrow={
                                        <div 
                                        style={{
                                            fontSize: '0px',
                                            backgroundColor:'white',
                                            height: '70px',
                                            width: '90px',
                                            borderRadius: '50%',
                                            position:'relative',
                                            paddingTop: '10px',
                                            paddingLeft: '10px'
                                        }}
                                        
                                        >{<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 30 24" strokeWidth={2} stroke="currentColor" className="w-6 h-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M11.25 4.5l7.5 7.5-7.5 7.5m-6-15l7.5 7.5-7.5 7.5" />
                                    </svg>
                                    }</div>}
                                >
                                    <div className="client-logo">
                                        <Link to="https://lithyusmusic.com/"><img src={logo_lithyusmusic} alt="logo_lithyusmusic" /></Link>
                                        <img src={fijao} alt="logo of fijao" />
                                        <Link to='https://aperoenergiemines.com'>
                                            <img src={apero} alt="apero energies et mines" />
                                        </Link>
                                    </div>
                                </Slide>
                            </div>
                        </div>
                        <div className="third-element">
                            <div className="content">
                                <div className="left">
                                    <h1>Introducing a new solution</h1>
                                    <img src={blob} alt="blob"/>
                                    <p>Adding more features to your web application</p>
                                    <Link to="/contact"><button>Request a quote</button></Link>                                   
                                </div>
                            </div>
                        </div>
                        <div className="fourth-element">
                            <div className="right">
                                <span>INTERACTIVE APP</span>
                                <h1>Engage with your customers</h1>
                                <img src={blob1} alt="blob1" />
                                <p>
                                    With us, create interactive applications for your clients. Offer your customers a unique creativity that best reflects your business and provides them with more engaging experiences
                                </p>
                                <Link to="/tarifs"><button>View our offers</button></Link>
                            </div>
                        </div>
                        <div className="fifty-element">
                            <div className="box1" style={{backgroundImage:`url(${fiftyBg})`, backgroundSize:'cover'}}>
                                
                            </div>
                            <div className="box">
                                <div className="left">
                                    <span>Save more</span>
                                    <h1>La quatilité au meilleur prix</h1>
                                    <img src={blob2} alt="blob2" />
                                    <p>
                                        With us, create interactive applications for your clients. Offer more experience 
                                        to your customers with a unique creativity that reflects your business at its 
                                        best. Don't spend a lot on the digitalization of your applications anymore. With 
                                        the significant number of experts in our company covering multiple domains, get 
                                        accompanied in the digitalization process without having to spend enormous sums 
                                        of money
                                    </p>
                                    <Link to="/tarifs"><button>View our offers</button></Link>
                                </div>
                            </div>
                        </div>
                        <div className="sixty-element">
                            <h1>Our areas of expertise</h1>
                            <div className="content">
                                <div className="content-box">
                                    <div className="dev">
                                        <img src={dev} alt="" />
                                        <h2>Développement</h2>
                                        <p>
                                            We offer services for the creation, maintenance, and management of interactive 
                                            websites and mobile applications
                                        </p>
                                    </div>
                                    <div className="dev">
                                        <img src={webDesign} alt="web-design" />
                                        <h2>Web design</h2>
                                        <p>
                                            Our web designers will assist you in designing and implementing 
                                            graphical interfaces that combine efficiency and aesthetics.                     
                                        </p>
                                    </div>
                                </div>
                                <div className="content-box">
                                    <div className="dev">
                                        <img src={digital} alt="market" />
                                        <h2>Digital Marketing</h2>
                                        <p>
                                            We offer services for the creation, maintenance, and management of interactive 
                                            websites and mobile applications
                                        </p>
                                    </div>
                                    <div className="dev">
                                        <img src={seo} alt="SEO" />
                                        <h2>Search Engine Optimization</h2>
                                        <p>
                                            Tailored and transparent SEO solutions to increase your visibility on Google
                                        </p>
                                    </div>
                                </div>
                                <div className="content-box">
                                    <div className="dev">
                                        <img src={rs} alt="social network" />
                                        <h2>Social Network</h2>
                                        <p>
                                            Support in your social media presence, content creation, and social strategy
                                        </p>
                                    </div>
                                    <div className="dev">
                                        <img src={ecommerce} alt="E-commerce" />
                                        <h2>E-commerce</h2>
                                        <p>
                                            Brochure websites, catalogs, e-commerce, portals. We are ready to take on the challenge
                                        </p>
                                    </div>    
                                </div>

                                
                            </div>
                        </div>
                        <div className="seven-element">
                            <div className="content">
                                <div className="left">
                                    <h1>Start Your Digital Project Now</h1>
                                    <img src={blob3} alt="blob3" />
                                    <p>Only 4 simple steps to optimize your business operations</p>
                                </div>
                                <div className="right-tel">
                                    <div className="right-content">
                                        <h1>Strategy</h1>
                                        <p>
                                            We create a customized strategy for you based on your needs, as well as an action plan to help you achieve your goals
                                        </p>
                                        <hr />
                                    </div>
                                    <div className="right-content">
                                        <h1>Réalisation</h1>
                                        <p>
                                            We turn your ideas into reality. We create responsive websites that adapt to all screens for a better customer experience
                                        </p>
                                        <hr />
                                    </div>
                                    <div className="right-content">
                                        <h1>Design</h1>
                                        <p>
                                            With a tailor-made design combining color, layout, and aesthetics, we bring your 
                                            web pages to life
                                        </p>
                                        <hr />
                                    </div>
                                    <div className="right-content">
                                        <h1>Support</h1>
                                        <p>
                                            We will remain by your side throughout the adaptation and learning process of your 
                                            various applications.
                                        </p>
                                        <hr />
                                    </div>
                                    <Link to="/contact"><button>Request a quote</button></Link>
                                </div>
                            </div>
                        </div>
                        <Temoignages />
                        <div className="nine-element">
                            <div className="content">
                                <div className="left">
                                <h1>DMM is ready to start working with you right away</h1>
                                <img src={lando} alt="lando"/>
                                <p>Start optimizing your business today.</p>
                                <Link to="/contact"><button>Request a quote</button></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="ordinateur">
                        <Fade {...settings}>
                            <div className="first-element" >
                                <div className="box1" style={{backgroundImage:`url(${bg1})`, backgroundSize:'cover'}}>
                                    
                                </div>
                                <div className="box" >
                                    <div className="left">
                                        <span className="free-quote">- FREE QUOTE</span>
                                        <h2>The best way to digitize your business</h2>
                                        <p>
                                            DevMasterMind is a cutting-edge company specialized 
                                            in the development of web and mobile applications, 
                                            as well as in the management and maintenance of 
                                            applications to help businesses achieve their digital
                                             objectives.
                                        </p>
                                        <div className="btns">
                                            <div>
                                                <Link to="/contact"><button className="devi">Request a quote</button></Link>
                                            </div>
                                            <div>
                                                <Link to="/tarifs"><button className="see-more">View our offers</button></Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="right">
                                        <img src={lando} alt="lando" />
                                    </div>
                                </div>
                            </div>
                            <div className="first-element">
                                    <div className="box1" style={{backgroundImage:`url(${bg2})`, backgroundSize:'cover'}}>
                                    
                                    </div>
                                    <div className="box" >
                                        <div className="left">
                                            <span className="free-quote">- FREE QUOTE</span>
                                            <h2>The best way to digitize your business</h2>
                                            <p>
                                                DevMasterMind is a cutting-edge company specialized 
                                                in the development of web and mobile applications, as well as 
                                                in the management and maintenance of applications to help businesses 
                                                achieve their digital objectives
                                            </p>
                                            <div className="btns">
                                                <div>
                                                    <Link to="/contact"><button className="devi">Request a quote</button></Link>
                                                </div>
                                                <div>
                                                    <Link to="/tarifs"><button className="see-more">View our offers</button></Link>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="right">
                                            <img src={lando} alt="lando" />
                                        </div>
                                    </div>
                            </div>
                            <div className="first-element">
                                    <div className="box1" style={{backgroundImage:`url(${bg3})`, backgroundSize:'cover'}}>
                                    
                                    </div>
                                    <div className="box" >
                                        <div className="left">
                                            <span className="free-quote">- FREE QUOTE</span>
                                            <h2>The best way to digitize your business</h2>
                                            <p>
                                                DevMasterMind is a cutting-edge company specialized 
                                                in the development of web and mobile applications, as well as 
                                                in the management and maintenance of applications to help businesses 
                                                achieve their digital objectives
                                            </p>
                                            <div className="btns">
                                                <div>
                                                    <Link to="/contact"><button className="devi">Request a quote</button></Link>
                                                </div>
                                                <div>
                                                    <Link to="/tarifs"><button className="see-more">View our offers</button></Link>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="right">
                                            <img src={lando} alt="lando" />
                                        </div>
                                    </div>
                            </div>
                            <div className="first-element">
                                    <div className="box1" style={{backgroundImage:`url(${bg4})`, backgroundSize:'cover'}}>
                                    
                                    </div>
                                    <div className="box" >
                                        <div className="left">
                                            <span className="free-quote">- FREE QUOTE</span>
                                            <h2>The best way to digitize your business</h2>
                                            <p>
                                                DevMasterMind is a cutting-edge company specialized 
                                                in the development of web and mobile applications, as well as 
                                                in the management and maintenance of applications to help businesses 
                                                achieve their digital objectives
                                            </p>
                                            <div className="btns">
                                                <div>
                                                    <Link to="/contact"><button className="devi">Request a quote</button></Link>
                                                </div>
                                                <div>
                                                    <Link to="/tarifs"><button className="see-more">View our offers</button></Link>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="right">
                                            <img src={lando} alt="lando" />
                                        </div>
                                    </div>
                            </div>
                        </Fade>
                        <div className="second-element">
                            <div className="title">
                                Several companies trust us
                            </div>
                            <div >
                                <Slide >
                                    <div className="client-logo">
                                        <Link to="https://lithyusmusic.com/"><img src={logo_lithyusmusic} alt="logo_lithyusmusic" /></Link>
                                        <img src={fijao} alt="logo of fijao" />
                                        <Link to='https://aperoenergiemines.com'>
                                            <img src={apero} alt="apero energies et mines" />
                                        </Link>
                                        <img src={bp} alt="" />
                                    </div>
                                </Slide>
                            </div>
                        </div>
                        <div className="third-element">
                            <div className="content">
                                <div className="left">
                                <h1>Introducing a new solution</h1>
                                <p>Adding more features to your web application</p>
                                <Link to="/contact"><button>Request a quote</button></Link>
                                </div>
                                <div className="right">
                                <img src={blob} alt="blob"/>
                                </div>
                            </div>
                        </div>
                        <div className="fourth-element">
                            <div className="left">
                                <img src={blob1} alt="blob1" />
                            </div>
                            <div className="right">
                                <span>INTERACTIVE APP</span>
                                <h1>Engage with your customers</h1>
                                <p>
                                    With us, create interactive applications for your clients. Offer your customers a 
                                    unique creativity that best reflects your business and provides them with more 
                                    engaging experiences
                                </p>
                                <Link to="/tarifs"><button>View our offers</button></Link>
                            </div>
                        </div>
                        <div className="fifty-element">
                            <div className="box1" style={{backgroundImage:`url(${fiftyBg})`, backgroundSize:'cover'}}>
                                
                            </div>
                            <div className="box">
                                <div className="left">
                                    <span>Save more</span>
                                    <h1>Quality at the best price</h1>
                                    <p>
                                        With us, create interactive applications for your clients. Offer your customers a 
                                        unique creativity that best reflects your business and provides them with more 
                                        engaging experiences. Don't spend too much on digitalizing your applications. With a 
                                        large number of experts in our company covering various domains, get assisted in 
                                        digitalization without having to spend huge sums of money
                                    </p>
                                    <Link to="/tarifs"><button>View our offers</button></Link>
                                </div>
                                <div className="right">
                                    <img src={blob2} alt="blob2"/>
                                </div>
                            </div>
                        </div>
                        <div className="sixty-element">
                            <h1>Our areas of expertise</h1>
                            <div className="content">
                                <div className="content-box">
                                    <div className="dev">
                                        <img src={dev} alt="" />
                                        <h2>Development</h2>
                                        <p>
                                            Creation, maintenance, and management of interactive websites and mobile applications
                                        </p>
                                    </div>
                                    <div className="dev">
                                        <img src={webDesign} alt="web-design" />
                                        <h2>Web design</h2>
                                        <p>
                                            Our web designers accompany you in the design and realization of 
                                            graphic interfaces that combine efficiency and aesthetics.                            
                                        </p>
                                    </div>
                                    <div className="dev">
                                        <img src={digital} alt="market" />
                                        <h2>Digital marketing</h2>
                                        <p>
                                            Creation, maintenance, and management of interactive websites and mobile applications.
                                        </p>
                                    </div>
                                </div>
                                <div className="content-box">
                                    <div className="dev">
                                        <img src={seo} alt="SEO" />
                                        <h2>Search Engine Optimization</h2>
                                        <p>
                                            Tailored and transparent SEO solutions to increase your visibility on Google
                                        </p>
                                    </div>
                                    <div className="dev">
                                        <img src={rs} alt="social network" />
                                        <h2>Social Network</h2>
                                        <p>
                                            Support in your social media presence, content creation, and social strategy
                                        </p>
                                    </div>
                                    <div className="dev">
                                        <img src={ecommerce} alt="E-commerce" />
                                        <h2>E-commerce</h2>
                                        <p>
                                            Brochure websites, catalogs, e-commerce, portals. We are ready to take on the challenge
                                        </p>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                        <div className="seven-element">
                            <div className="content">
                                <div className="left">
                                    <img src={blob3} alt="blob3" />
                                    <h1>Start Your Digital Project Now</h1>
                                    <p>Only 4 simple steps to optimize your business operations</p>
                                    <Link to="/contact"><button>Request a quote</button></Link>
                                </div>
                                <div className="right">
                                    <div className="right-content">
                                        <h1>Strategy</h1>
                                        <p>
                                            We create a customized strategy for you based on your needs, as well as an 
                                            action plan to help you achieve your goals
                                        </p>
                                        <hr />
                                    </div>
                                    <div className="right-content">
                                        <h1>Implementation</h1>
                                        <p>
                                            We turn your ideas into reality. We create responsive websites that adapt to all 
                                            screens for a better customer experience
                                        </p>
                                        <hr />
                                    </div>
                                    <div className="right-content">
                                        <h1>Design</h1>
                                        <p>
                                            With a custom design combining color, layout, and aesthetics, we bring 
                                            your web pages to life.
                                        </p>
                                        <hr />
                                    </div>
                                    <div className="right-content">
                                        <h1>Support</h1>
                                        <p>
                                            We will stand by your side throughout the process of adapting and mastering 
                                            your various applications
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Temoignages />
                        <div className="nine-element">
                            <div className="content">
                                <div className="left">
                                <h1>DMM is ready to start working with you right away</h1>
                                <p>Start optimizing your business today.</p>
                                <Link to="/contact"><button>Request a quote</button></Link>
                                </div>
                                <div className="right">
                                <img src={lando} alt="blob"/>
                                </div>
                            </div>
                        </div>
                    </div>
                <NewsLetter />
                <Footer />
            </div>
        </React.Fragment>
    )
}

export default HomeEn;
