import React from "react"
import { Link } from "react-router-dom"
import Navbar from "../../../component/navbar/Navbar"
import Footer from "../../../component/footer/Footer"
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import "./confidentialites.scss"

function confidentialite() {
    return(
        <div className="conditions">
            <Navbar />
            <div className="corps">
                <div className="title">
                    <span>Politique de confidentialité de DEVMastermind</span>
                    <p>
                        Chez DEVMastermind, nous attachons une grande importance à la confidentialité de nos utilisateurs et à la 
                        protection de leurs informations personnelles. Cette politique de confidentialité décrit comment nous collectons, 
                        utilisons et protégeons les données personnelles que vous nous fournissez lorsque vous visitez notre site web. 
                        Veuillez prendre le temps de lire attentivement cette politique pour comprendre comment nous traitons vos 
                        informations.
                    </p>
                </div>
                <div className="body">
                    <span>Collecte d'informations personnelles</span>
                    <p>
                        Lorsque vous visitez notre site web, nous pouvons collecter certaines informations personnelles vous 
                        concernant, telles que votre nom, votre adresse e-mail, votre numéro de téléphone et d'autres informations 
                        pertinentes. Nous ne collecterons ces informations que si vous les fournissez volontairement, par exemple en 
                        remplissant un formulaire de contact ou en vous inscrivant à notre newsletter.
                    </p>
                    <span>Utilisation des informations</span>
                    <div>
                        <p>Nous utilisons les informations personnelles que nous collectons pour les finalités suivantes:</p>
                        <div className="fiber">
                            <FiberManualRecordIcon className="icon"/>
                            Répondre à vos demandes de renseignements et fournir les services que vous avez demandés.
                        </div>
                        <div className="fiber">
                            <FiberManualRecordIcon className="icon"/>
                            Vous envoyer des informations importantes sur nos services, des mises à jour ou des communications 
                            marketing que nous pensons susceptibles de vous intéresser.                        
                        </div>
                        <div className="fiber">
                            <FiberManualRecordIcon className="icon"/>
                            Améliorer notre site web et nos services en fonction de vos commentaires et de vos besoins.
                        </div>
                        <div className="fiber">
                            <FiberManualRecordIcon className="icon"/>
                            Analyser les tendances et les statistiques d'utilisation pour mieux comprendre les préférences de nos 
                            utilisateurs.
                        </div>
                    </div>
                    <span>Partage des informations</span>
                    <div>
                        <p>
                            Nous ne vendons, n'échangeons ni ne louons vos informations personnelles à des tiers sans votre 
                            consentement, sauf dans les cas suivants :
                        </p>
                        <div className="fiber">
                            <FiberManualRecordIcon className="icon"/>
                            Lorsque nous sommes tenus de le faire par la loi, une réglementation ou une demande légale.
                        </div>
                        <div className="fiber">
                            <FiberManualRecordIcon className="icon"/>
                            Lorsque nous pensons de bonne foi que la divulgation est nécessaire pour protéger nos droits, votre 
                            sécurité ou celle des autres utilisateurs.                   
                        </div>
                        <div className="fiber">
                            <FiberManualRecordIcon className="icon"/>
                            Lorsque cela est nécessaire pour assurer le bon fonctionnement de notre entreprise, y compris le traitement 
                            des paiements ou la gestion des données.
                        </div>
                    </div>
                    <span>Sécurité des informations</span>
                    <p>
                        Nous mettons en place des mesures de sécurité appropriées pour protéger vos informations personnelles contre 
                        tout accès non autorisé, toute divulgation, toute altération ou destruction accidentelle ou illégale. Cependant,
                         veuillez noter qu'aucune méthode de transmission sur Internet ou de stockage électronique n'est totalement 
                         sécurisée, et nous ne pouvons garantir la sécurité absolue de vos informations.
                    </p>
                    <span>Cookies et technologies similaires</span>
                    <p>
                        Nous utilisons des cookies et d'autres technologies similaires pour collecter des informations sur votre 
                        utilisation de notre site web. Ces informations sont utilisées pour améliorer votre expérience de navigation, 
                        personnaliser le contenu et les publicités, et analyser les tendances d'utilisation. Vous pouvez configurer 
                        votre navigateur pour qu'il refuse les cookies ou vous avertisse lorsque des cookies sont envoyés. Veuillez 
                        noter que certaines fonctionnalités de notre site web peuvent ne pas fonctionner correctement si les cookies 
                        sont désactivés.
                    </p>
                    <span>Liens vers des sites tiers</span>
                    <p>
                        Notre site web peut contenir des liens vers des sites tiers. Cette politique de confidentialité ne s'applique 
                        pas à ces sites, et nous vous encourageons à consulter les politiques de confidentialité respectives de ces 
                        sites.
                    </p>
                    <span>Modification de la politique de confidentialité</span>
                    <p>
                        Nous nous réservons le droit de modifier cette politique de confidentialité à tout moment. Les modifications 
                        seront publiées sur notre site web avec la date de leur entrée en vigueur. Nous vous encourageons à consulter 
                        régulièrement cette politique de confidentialité pour rester informé des éventuelles mises à jour.
                    </p>
                    <span>Consentement</span>
                    <p>
                        En utilisant notre site web, vous consentez à la collecte, à l'utilisation et à la protection de vos 
                        informations personnelles telles que décrites dans cette politique de confidentialité.
                    </p>
                    <span>Accès et contrôle de vos informations</span>
                    <p>
                        Vous avez le droit d'accéder à vos informations personnelles que nous détenons et de les mettre à jour ou de 
                        les supprimer si nécessaire. Si vous souhaitez exercer ce droit, veuillez nous contacter en utilisant les 
                        coordonnées fournies à la fin de cette politique.
                    </p>
                    <span>Protection des mineurs</span>
                    <p>
                        Notre site web ne s'adresse pas aux personnes de moins de 18 ans. Nous ne collectons pas sciemment 
                        d'informations personnelles auprès de personnes de moins de 18 ans. Si vous êtes un parent ou un tuteur et que 
                        vous pensez que votre enfant nous a fourni des informations personnelles, veuillez nous contacter afin que nous 
                        puissions supprimer ces informations de nos systèmes.
                    </p>
                    <span>Transfert international des données</span>
                    <p>
                        Veuillez noter que vos informations personnelles peuvent être transférées et traitées dans des pays autres que 
                        votre pays de résidence. Ces pays peuvent avoir des lois sur la protection des données qui diffèrent de celles 
                        de votre pays. Toutefois, nous prendrons les mesures nécessaires pour garantir que vos informations 
                        personnelles bénéficient d'un niveau de protection adéquat conformément aux lois applicables en matière de 
                        protection des données.
                    </p>
                    <span>Conservation des données</span>
                    <p>
                        Nous ne conserverons vos informations personnelles que le temps nécessaire aux fins pour lesquelles elles ont 
                        été collectées, sauf si la loi l'exige autrement. Nous prendrons des mesures pour nous assurer que les 
                        informations personnelles sont correctes, à jour et pertinentes pour leur utilisation prévue.
                    </p>
                    <span>Communication commerciale</span>
                    <p>
                        Avec votre consentement, nous pouvons utiliser vos informations personnelles pour vous envoyer des 
                        communications commerciales, telles que des newsletters, des offres spéciales et des informations sur nos 
                        produits et services. Vous pouvez choisir de ne plus recevoir ces communications à tout moment en suivant 
                        les instructions de désabonnement fournies dans les communications elles-mêmes.
                    </p>
                    <span>Responsabilité des utilisateurs</span>
                    <p>
                        Il est important de rappeler que vous êtes responsable de la confidentialité des informations que vous partagez 
                        publiquement sur notre site web, telles que les commentaires, les avis ou les messages sur les forums. Nous ne 
                        pouvons pas contrôler les actions des autres utilisateurs avec lesquels vous interagissez sur notre site, et nous 
                        déclinons toute responsabilité en cas d'utilisation abusive ou de divulgation de vos informations personnelles de 
                        cette manière.
                    </p>
                    <span>Application de la politique de confidentialité</span>
                    <p>
                        Nous nous engageons à respecter les principes énoncés dans cette politique de confidentialité et à nous 
                        conformer aux lois applicables en matière de protection des données. Si vous avez des préoccupations concernant 
                        notre respect de cette politique ou si vous estimez que vos droits en matière de confidentialité ont été 
                        violés, veuillez nous contacter en utilisant les coordonnées fournies ci-dessus. Nous enquêterons sur les 
                        plaintes et prendrons les mesures appropriées pour remédier à toute violation de confidentialité.<br/><br/>
                        Nous vous remercions de la confiance que vous accordez à DEVMastermind. Nous nous engageons à protéger vos 
                        informations personnelles et à assurer la confidentialité de vos données.
                    </p>
                    <Link to="/contact"><button>Contacter</button></Link>
                </div>
            </div>
            <Footer />
        </div>

    )
}

export default confidentialite;