import React from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import marke from "../../../assets/iconmap.png"
import L from "leaflet";
import "../../fr/contact/map.scss"


function Map() {
    const icon = L.icon({
        iconUrl: marke,
        iconSize: [25, 50],
        iconAnchor: [1, 41],
      });
    return (
      <MapContainer center={[33.5883102, -7.6113801]} zoom={13} scrollWheelZoom={true} className="map-box" attributionControl={false}>
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
        <Marker position={[33.5883102, -7.6113801]} icon={icon}>
          <Popup>
            DevMasterMind. <br /> Ouagadougou. <br />
          </Popup>
        </Marker>
      </MapContainer>
    );
  }
  
  export default Map;