import React, { useState } from "react"
import Map from "./map"
// import blob6 from "../../../../assets/blob6.jpg" 
import contact from "../../../assets/contact2.png" 
import Navbar from "../../../component/navbar/Navbar"
import NewsLetter from "../../../component/news-letter/NewsLetter"
import Footer from "../../../component/footer/Footer"
import "./contact.scss"

function Contact() {
    const [formData, setFormData] = useState({
        nomPrenom:"",
        email:"",
        tel:"",
        message:"",
        subject:""
    });
    const [message, setMessage] = useState("");
    const [error, setError] = useState("");
    const handleChange = (event) => {
        setFormData((prev) => ({
            ...prev, [event.target.name]:event.target.value
        }));
    }
    const handleSubmit = (event) => {
        event.preventDefault();
        const config = {
            SecureToken : "b7b04988-ad60-4bd8-af19-abca19c73d94",
            To : 'contact@dev-mastermind.com',
            From : "pezongomickael67@gmail.com",
            Subject : formData.subject,
            Body : `Mr ${formData.nomPrenom}<br>
                    Email: ${formData.email}<br>
                    Telephone:${formData.tel}<br>
                    Message:${formData.message}`
        }
        if(window.Email){
            window.Email.send(config)
            .then(message => {
                if(message === "OK"){
                    setFormData({
                        nomPrenom:"",
                        email:"",
                        tel:"",
                        message:"",
                        subject:""
                    });
                    setMessage("success");
                }else{
                    setError("error");
                }
            })
            .catch(error => console.error(error));
        }
    }
    return(
        <React.Fragment>
            <Navbar page = "contact"/>
            <div className="formulaire">
                <div className="left">
                    <img src={contact} alt="" />
                </div>
                <div className="right">
                    <span>Nous contacter</span>
                    <p>Explorez le monde digital avec nous</p>
                    <form onSubmit={handleSubmit}>
                        <label htmlFor="nomPrenom">Nom & Prénom</label>
                        <input 
                            type="text" 
                            placeholder="Nom Prénom" 
                            id="nomPrenom" 
                            name="nomPrenom" 
                            className="nomPrenom"
                            value={formData.nomPrenom}
                            required
                            onChange={handleChange}
                        />
                        <label htmlFor="email">Email</label>
                        <input 
                            type="email" 
                            placeholder="Adresse Email" 
                            id="email" 
                            name="email" 
                            className="email"
                            value={formData.email}
                            required
                            onChange={handleChange}
                        />
                        <label htmlFor="tel">Téléphone</label>
                        <input 
                            type="tel" 
                            placeholder="Numero de téléphone" 
                            id="tel" 
                            name="tel" 
                            className="telephone"
                            value={formData.tel}
                            required
                            onChange={handleChange}
                        />
                        <label htmlFor="subject">Sujet</label>
                        <input 
                            type="text" 
                            placeholder="Sujet" 
                            id="subject" 
                            name="subject" 
                            className="telephone"
                            value={formData.subject}
                            required
                            onChange={handleChange}
                        />
                        <label htmlFor="message">Message</label>
                        <textarea 
                            name="message" 
                            id="message" 
                            placeholder="Que voulez vous nous dire?"
                            value={formData.message}
                            required
                            onChange={handleChange}
                        ></textarea>
                        <input type="submit" value="Envoyer"/>
                    </form>
                    {
                        message
                            ?
                                <div className="success_message">
                                    Message envoyé avec succès
                                </div>
                            :null
                    }
                    {
                        error
                            ?
                                <div className="error_message">
                                    Une erreur s'est produite veillez réessayer
                                </div>
                            :null
                    }
                </div>
            </div>
            <div className="horaire">
                <div className="top">
                    <span>Prendre rendez-vous</span>
                    <p>
                        Discutez avec nos experts directement dans nos locaux pour en 
                        apprendre d'avantage sur notre entreprise
                    </p>
                </div>
                <div className="bottom">
                    <table border="1" cellSpacing="0" align="center">
                        <tbody>
                            <tr className="first-line">
                                <th className="day">Horaire</th>
                                <th>8h-10h</th>
                                <th>10h-12h</th>
                                <th>12h-14h</th>
                                <th>14h-16h</th>
                                <th>16h-18h</th>
                            </tr>
                            <tr>
                                <td className="day">Lundi</td>
                                <td className="indisponible">Indisponible</td>
                                <td className="disponible">Prendre ce crenaux</td>
                                <td className="disponible">Prendre ce crenaux</td>
                                <td className="disponible">Prendre ce crenaux</td>
                                <td className="disponible">Prendre ce crenaux</td>
                            </tr>
                            <tr>
                                <td className="day">Mardi</td>
                                <td className="disponible">Prendre ce crenaux</td>
                                <td className="disponible">Prendre ce crenaux</td>
                                <td className="disponible">Prendre ce crenaux</td>
                                <td className="disponible">Prendre ce crenaux</td>
                                <td className="disponible">Prendre ce crenaux</td>
                            </tr>
                            <tr>
                                <td className="day">Mercredi</td>
                                <td className="indisponible">Indisponible</td>
                                <td className="indisponible">Indisponible</td>
                                <td className="indisponible">Indisponible</td>
                                <td className="indisponible">Indisponible</td>
                                <td className="indisponible">Indisponible</td>
                            </tr>
                            <tr>
                                <td className="day">Jeudi</td>
                                <td className="indisponible">Indisponible</td>
                                <td className="indisponible">Indisponible</td>
                                <td className="disponible">Prendre ce crenaux</td>
                                <td className="disponible">Prendre ce crenaux</td>
                                <td className="disponible">Prendre ce crenaux</td>
                            </tr>
                            <tr>
                                <td className="day">Vendredi</td>
                                <td className="indisponible">Indisponible</td>
                                <td className="disponible">Prendre ce crenaux</td>
                                <td className="disponible">Prendre ce crenaux</td>
                                <td className="indisponible">Indisponible</td>
                                <td className="disponible">Prendre ce crenaux</td>
                            </tr>
                            <tr>
                                <td className="day">Samedi</td>
                                <td className="disponible">Prendre ce crenaux</td>
                                <td className="disponible">Prendre ce crenaux</td>
                                <td className="indisponible">Indisponible</td>
                                <td className="indisponible">Indisponible</td>
                                <td className="indisponible">Indisponible</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            
            <div className="map">
                <hr /> 
                <Map />
            </div>
            <NewsLetter />
            <Footer />
        </React.Fragment>
    )
}

export default Contact;