import React, { useState } from "react";

import Navbar from "../../../component/navbar/Navbar";
import Footer from "../../../component/footer/Footer";
import NewsLetters from "../../../component/news-letter/NewsLetter";

import ideas from '../../../assets/ideass.png'

import './projet.scss'

function Project(){
    const [firstStepValue, setFirstStepValue] = useState(JSON.parse(localStorage.getItem("firstStep")) || {
        project:"",
        finance:"",
        project_name:"",
        budget:"",
        apport:"",
        besoins:"",
        description:"",
        attente:"",
    })
    const [secondStepValue, setSecondStepValue] = useState(JSON.parse(localStorage.getItem("secondStep")) || {
        nom:"",
        prenom:"",
        email:"",
        adresse:"",
        pays:"",
        tel:"",
        profession:"",
    })

    const [firstStepValueError, setFirstStepValueError] = useState({
        project:"",
        finance:"",
        project_name:"",
        budget:"",
        apport:"",
        besoins:"",
        attente:"",
    })
    const [secondStepValueError, setSecondStepValueError] = useState({
        nom:"",
        prenom:"",
        email:"",
        adresse:"",
        pays:"",
        tel:"",
        profession:"",
    })
    const [step, setStep] = useState(JSON.parse(localStorage.getItem("step")) || false);
    const [message, setMessage] = useState("");
    const [error, setError] = useState("");

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFirstStepValue({ ...firstStepValue, [name]: value });
    }
    const handleChangeStep2 = (event) => {
        const { name, value } = event.target;
        setSecondStepValue({ ...secondStepValue, [name]: value });
    }

    const handleClickStep1 = (event) => {
        event.preventDefault();
        const newErrors = {}
        if (!firstStepValue.project) newErrors.project = "Veillez choisir votre type de projet"
        if (!firstStepValue.finance) newErrors.finance = "Veillez votre type de besoin de financement"
        if (!firstStepValue.project_name) newErrors.project_name = "Veillez definir le nom de votre projet"
        if (!firstStepValue.budget) newErrors.budget = "Veillez donner le budget approximative de votre projet"
        if (!firstStepValue.apport) newErrors.apport = "Veillez préciser votre apport personnel"
        if (!firstStepValue.besoins) newErrors.besoins= "Veillez expliquer les besoins en détails de votre projet"
        if (!firstStepValue.description) newErrors.description = "Veillez donner une brève description de votre projet"
        if (!firstStepValue.attente) newErrors.attente = "Veillez nous dire ce que vous attendez de nous"
        
        if (Object.keys(newErrors).length > 0) {
            setFirstStepValueError(newErrors);
            return;
        }else{
            localStorage.setItem("firstStep", JSON.stringify(firstStepValue));
            setStep(true);
            localStorage.setItem("step", true);
            window.scrollTo({
                top: 500,
                behavior: 'smooth', // Pour un défilement fluide
            });
            
        }
    }
    console.log(window.scrollY)
    const handleClickPrec = (event) => {
        event.preventDefault();
        localStorage.setItem("secondStep", JSON.stringify(secondStepValue));
        setStep(false);
        localStorage.setItem("step", false);
        window.scrollTo({
            top: 500,
            behavior: 'smooth', // Pour un défilement fluide
        });
    }
    const handleClickStep2 = (event) => {
        event.preventDefault();
        const newErrors = {}
        if (!secondStepValue.nom) newErrors.nom = "Veillez entrer votre nom"
        if (!secondStepValue.prenom) newErrors.prenom = "Veillez entrer votre prenom"
        if (!secondStepValue.email) newErrors.email = "Veillez entrer votre adresse mail"
        if (!secondStepValue.adresse) newErrors.adresse = "Veillez entrer votre adresse de residence"
        if (!secondStepValue.pays) newErrors.pays = "Veillez choisir votre pays actuelle"
        if (!secondStepValue.tel) newErrors.tel = "Veillez entrer votre numero de telephone"
        if (!secondStepValue.profession) newErrors.profession = "Veillez choisir votre profession actuelle"
        const emailBody = `
    <!DOCTYPE html>
    <html lang="en">
    <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <link href="https://fonts.googleapis.com/css?family=Poppins&display=swap" rel="stylesheet">
        <link href="https://fonts.googleapis.com/css?family=Inter&display=swap" rel="stylesheet">
        <title>Document</title>
    </head>
    <body>
        <div style="
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background-color: white;
            width: 100%;
        ">
            <div style="
                width: 600px;
                background-color: #f4f7fa;
                height: auto; 
                padding: 20px;
            ">
                <h1 style="
                    color: #3164F4;
                    text-align: center;
                ">
                    Projet
                </h1>
                <div>
                    <h3>Informations Personnelles</h3>
                    <table border="1" cellSpacing="0" width="100%">
                        <tr>
                            <td width="50%">
                                Nom
                            </td>
                            <td width="50%">
                                ${secondStepValue.nom}
                            </td>
                        </tr>
                        <tr>
                            <td width="50%">
                                Prenom
                            </td>
                            <td width="50%">
                                ${secondStepValue.prenom}
                            </td>
                        </tr>
                        <tr>
                            <td width="50%">
                                Email
                            </td>
                            <td width="50%">
                                ${secondStepValue.email}
                            </td>
                        </tr>
                        <tr>
                            <td width="50%">
                                Profession
                            </td>
                            <td width="50%">
                                ${secondStepValue.profession}
                            </td>
                        </tr>
                        <tr>
                            <td width="50%">
                                Pays
                            </td>
                            <td width="50%">
                                ${secondStepValue.pays}
                            </td>
                        </tr>
                        <tr>
                            <td width="50%">
                                Adresse
                            </td>
                            <td width="50%">
                                ${secondStepValue.adresse}
                            </td>
                        </tr>
                        <tr>
                            <td width="50%">
                                Telephone
                            </td>
                            <td width="50%">
                                ${secondStepValue.tel}
                            </td>
                        </tr>
                    </table>
                </div>
                <div>
                    <h3>Informations sur le projet</h3>
                    <table border="1" cellSpacing="0" width="100%">
                        <tr>
                            <td width="50%">
                                Type de projet
                            </td>
                            <td width="50%">
                                ${firstStepValue.project}
                            </td>
                        </tr>
                        <tr>
                            <td width="50%">
                                Que souhaitez vous financer ?
                            </td>
                            <td width="50%">
                                ${firstStepValue.finance}
                            </td>
                        </tr>
                        <tr>
                            <td width="50%">
                                Nom du projet
                            </td>
                            <td width="50%">
                                ${firstStepValue.project_name}
                            </td>
                        </tr>
                        <tr>
                            <td width="50%">
                                Budget necessaire
                            <td width="50%">
                                ${firstStepValue.budget}
                            </td>
                        </tr>
                        <tr>
                            <td width="50%">
                                Apport personnelles
                            </td>
                            <td width="50%">
                                ${firstStepValue.apport}
                            </td>
                        </tr>
                        <tr>
                            <td width="50%">
                                Description du projet
                            </td>
                            <td width="50%">
                                ${firstStepValue.description}
                            </td>
                        </tr>
                        <tr>
                            <td width="50%">
                                Besoins Specifiques du projet
                            </td>
                            <td width="50%">
                                ${firstStepValue.besoins}
                            </td>
                        </tr>
                        <tr>
                            <td width="50%">
                                Attente de DevMasterMind
                            </td>
                            <td width="50%">
                                ${firstStepValue.attente}
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </body>
    </html>
        `
        if (Object.keys(newErrors).length > 0) {
            setSecondStepValueError(newErrors);
            return;
        }else{
            localStorage.setItem("secondStep", JSON.stringify(secondStepValue));
            setStep(false);
            localStorage.setItem("step", false);
            const config = {
                SecureToken : "b7b04988-ad60-4bd8-af19-abca19c73d94",
                To : 'contact@dev-mastermind.com',
                From : "pezongomickael67@gmail.com",
                Subject : `Besoin de creation de projet`,
                Body : emailBody,
            }
            if(window.Email){
                window.Email.send(config)
                .then(message => {
                    if(message === "OK"){
                        setFirstStepValue({
                            project:"",
                            finance:"",
                            project_name:"",
                            budget:"",
                            apport:"",
                            besoins:"",
                            description:"",
                            attente:"",
                        });
                        setSecondStepValue({
                            nom:"",
                            prenom:"",
                            email:"",
                            adresse:"",
                            pays:"",
                            tel:"",
                            profession:"",
                        });
                        setSecondStepValueError({
                            nom:"",
                            prenom:"",
                            email:"",
                            adresse:"",
                            pays:"",
                            tel:"",
                            profession:"", 
                        })
                        setFirstStepValueError({
                            project:"",
                            finance:"",
                            project_name:"",
                            budget:"",
                            apport:"",
                            besoins:"",
                            attente:"",
                        })
                        localStorage.removeItem('firstStep');
                        localStorage.removeItem('secondStep');
                        localStorage.removeItem('step')
                        setMessage("success");
                    }else{
                        setError("error");
                    }
                })
                .catch(error => console.error(error));
            }
        }
    }
    return(
        <div className="projet">
           <Navbar page="partner" />
           <div className="projet-body">
                <div className="title">
                    <h1>Réalisons ensemble vos idées ?</h1>
                </div>
                <div className="first-block">
                    <div className="left">
                        <div className="first-block-left-title">
                            Avez-vous une idée de projet ?
                        </div>
                        <br /><br />
                        <span>
                            Bienvenue sur notre page dédiée aux idées de projet ! Vous avez une vision créative qui vous 
                            brûle d'envie de réaliser, mais vous avez besoin d'un coup de main pour concrétiser votre idée ?
                            Vous êtes au bon endroit ! Ici, nous vous invitons à partager vos idées les plus innovantes et 
                            ambitieuses, et si elles nous enthousiasment, nous serons ravis de vous accompagner dans leur 
                            concrétisation. Ensemble, construisons l'avenir du développement web ! 🚀
                        </span>
                    </div>
                    <div className="right">
                        <img src={ideas} alt="avez vous une idée" />
                    </div>
                </div>
                <div className={"second-block " + (step ? "inactive" : "")}>
                    <h1 className="etapes">
                        ETAPE 1/2
                    </h1>
                    <span>
                        Les questions suivies d'un <span style={{color:'red'}}>*</span> sont obligatoires
                    </span>
                    <form>
                    <div className="form-box">
                        <h1>Quel est votre projet ? <span style={{color:'red'}}>*</span></h1>
                        <div className="radio-form">
                            <input
                            
                            value="Création d'entreprise"
                            name="project"
                            type="radio"
                            id="create"
                            onChange={handleChange}
                            checked={firstStepValue.project === "Création d'entreprise"}
                            />
                            <label htmlFor="create"><span></span>Création d'entreprise</label>
                            <input
                            
                            value="entreprise existante"
                            name="project"
                            type="radio"
                            id="exist"
                            onChange={handleChange}
                            checked={firstStepValue.project === "entreprise existante"}
                            />
                            <label htmlFor="exist"><span></span>Besoin d'aide dans une entreprise existante</label>
                            <div className="worm">
                            <div className="worm__segment"></div>
                            </div>
                        </div>
                        {/* Affichez l'erreur si nécessaire */}
                        {firstStepValueError.project && <span style={{ color: 'red' }}>{firstStepValueError.project}</span>}
                    </div>
                        <div className="form-box">
                            <h1>Que souhaitez vous financer ? <span style={{color:'red'}}>*</span></h1>
                            <div className="radio-form">
                                <input
                                    onChange={handleChange}
                                    
                                    value="Le project dans son ensemble"
                                    name="finance"
                                    type="radio"
                                    id="b"
                                    checked={firstStepValue.finance === "Le project dans son ensemble"}
                                />
                                <label htmlFor="b"><span></span>Le project dans son ensemble</label>
                                <input
                                    onChange={handleChange}
                                    
                                    value="Une partie du projet"
                                    name="finance"
                                    type="radio"
                                    id="c"
                                    checked={firstStepValue.finance === "Une partie du projet"}
                                />
                                <label htmlFor="c"><span></span>Une partie du projet</label>
                                <div className="worm">
                                    <div className="worm__segment"></div>
                                </div>
                            </div>
                            {
                                firstStepValueError.finance
                                    ? <span style={{color:'red'}}>{firstStepValueError.finance}</span>
                                    :null
                            }
                        </div>
                        <div className="form-box">
                            <h1>Nom de votre projet <span style={{color:'red'}}>*</span></h1>
                            <input value={firstStepValue.project_name} onChange={handleChange}  type="text" name="project_name" className="form-box-input" placeholder="Nom du projet"/>
                            {
                                firstStepValueError.project_name
                                    ? <span style={{color:'red'}}>{firstStepValueError.project_name}</span>
                                    :null
                            }
                        </div>
                        <div className="form-box">
                            <h1>Quel est le budget nécessaire ? <span style={{color:'red'}}>*</span></h1>
                            <input value={firstStepValue.budget} onChange={handleChange}  type="text" name="budget" className="form-box-input" placeholder="le budget suffisant selon votre études ou estimation"/>
                        </div>
                        <div className="form-box">
                            <h1>Quel est votre apport ? <span style={{color:'red'}}>*</span></h1>
                            <input value={firstStepValue.apport} onChange={handleChange}  type="text" name="apport" className="form-box-input" placeholder="Exprimé en pourcentage ou compétences"/>
                            {
                                firstStepValueError.budget
                                    ? <span style={{color:'red'}}>{firstStepValueError.budget}</span>
                                    :null
                            }
                        </div>
                        <div className="form-box">
                            <h1>Description du projet ? <span style={{color:'red'}}>*</span></h1>
                            <textarea value={firstStepValue.description} onChange={handleChange}  name="description" id="" cols="30" rows="10" className="attente" />
                            {
                                firstStepValueError.description
                                    ? <span style={{color:'red'}}>{firstStepValueError.description}</span>
                                    :null
                            }
                        </div>
                        <div className="form-box">
                            <h1>Quels sont les besoins spécifiques du projet ? <span style={{color:'red'}}>*</span></h1>
                            <textarea value={firstStepValue.besoins} onChange={handleChange}  name="besoins" id="" cols="30" rows="10" />
                            {
                                firstStepValueError.besoins
                                    ? <span style={{color:'red'}}>{firstStepValueError.besoins}</span>
                                    :null
                            }
                        </div>
                        <div className="form-box">
                            <h1>Qu'attendez vous de DevMasterMind ? <span style={{color:'red'}}>*</span></h1>
                            <textarea value={firstStepValue.attente} onChange={handleChange}  name="attente" id="" cols="30" rows="10" className="attente" />
                            {
                                firstStepValueError.attente
                                    ? <span style={{color:'red'}}>{firstStepValueError.attente}</span>
                                    :null
                            }
                        </div>
                        <div className="form-box">
                            <button type="submit" onClick={handleClickStep1}>Suivant</button>
                            {
                                !Object.values(firstStepValueError).every((value) => value === "")
                                    ? <span style={{color:'red'}}>Veillez remplir tous les champs avec (*)</span>
                                    :null
                            }
                        </div>
                    </form>
                    {
                        message
                            ?
                                <div className="project_success_message">
                                    Message envoyé avec succès
                                </div>
                            :null
                    }
                    {
                        error
                            ?
                                <div className="project_error_message">
                                    Une erreur s'est produite veillez réessayer
                                </div>
                            :null
                    }
                </div>
                <div className={"second-block " +  (!step ? "inactive" : "")}>
                    <h1 className="etapes">
                        ETAPE 2/2
                    </h1>
                    <span style={{color:'green'}}>
                        Vos informations personnelles
                    </span>
                    <span>
                        Les questions suivies d'un <span style={{color:'red'}}>*</span> sont obligatoires
                    </span>
                    <form>
                        <div className="form-box">
                            <h1>Nom <span style={{color:'red'}}>*</span></h1>
                            <input value={secondStepValue.nom} onChange={handleChangeStep2}  type="text" name="nom" className="form-box-input" placeholder="Nom de Famille"/>
                            {
                                secondStepValueError.nom
                                    ? <span style={{color:'red'}}>{secondStepValueError.nom}</span>
                                    :null
                            }
                        </div>
                        <div className="form-box">
                            <h1>Prénom <span style={{color:'red'}}>*</span></h1>
                            <input value={secondStepValue.prenom} onChange={handleChangeStep2}  type="text" name="prenom" className="form-box-input" placeholder="Prenom"/>
                            {
                                secondStepValueError.prenom
                                    ? <span style={{color:'red'}}>{secondStepValueError.prenom}</span>
                                    :null
                            }
                        </div>
                        <div className="form-box">
                            <h1>Email <span style={{color:'red'}}>*</span></h1>
                            <input value={secondStepValue.email} onChange={handleChangeStep2}  type="email" name="email" className="form-box-input" placeholder="Votre adresse mail"/>
                            {
                                secondStepValueError.email
                                    ? <span style={{color:'red'}}>{secondStepValueError.email}</span>
                                    :null
                            }
                        </div>
                        <div className="form-box">
                            <h1>Profession <span style={{color:'red'}}>*</span></h1>
                            <div className="radio-form">
                                <input
                                    onChange={handleChangeStep2}
                                    
                                    value="etudiant"
                                    name="profession"
                                    type="radio"
                                    id="etudiant"
                                    checked={secondStepValue.profession === "etudiant"}
                                />
                                <label htmlFor="etudiant"><span></span>Etudiant</label>
                                <input
                                    onChange={handleChangeStep2}
                                    
                                    value="particulier"
                                    name="profession"
                                    type="radio"
                                    id="particulier"
                                    checked={secondStepValue.profession === "particulier"}
                                />
                                <label htmlFor="particulier"><span></span>Particulier</label>
                                <div className="worm">
                                    <div className="worm__segment"></div>
                                </div>
                            </div>
                            {
                                secondStepValueError.profession
                                    ? <span style={{color:'red'}}>{secondStepValueError.profession}</span>
                                    :null
                            }
                        </div>
                        <div className="form-box">
                            <h1>Pays <span style={{color:'red'}}>*</span></h1>
                            <input value={secondStepValue.pays} onChange={handleChangeStep2}  type="text" name="pays" className="form-box-input" placeholder="Pays de residence"/>
                            {
                                secondStepValueError.pays
                                    ? <span style={{color:'red'}}>{secondStepValueError.pays}</span>
                                    :null
                            }
                        </div>
                        <div className="form-box">
                            <h1>Adresse <span style={{color:'red'}}>*</span></h1>
                            <input value={secondStepValue.adresse} onChange={handleChangeStep2}  type="text" name="adresse" className="form-box-input" placeholder="Adresse actuelle"/>
                            {
                                secondStepValueError.adresse
                                    ? <span style={{color:'red'}}>{secondStepValueError.adresse}</span>
                                    :null
                            }
                        </div>
                        <div className="form-box">
                            <h1>Telephone <span style={{color:'red'}}>*</span></h1>
                            <input value={secondStepValue.tel} onChange={handleChangeStep2}  type="tel" name="tel" className="form-box-input" placeholder="Numero de téléphone"/>
                            {
                                secondStepValueError.tel
                                    ? <span style={{color:'red'}}>{secondStepValueError.tel}</span>
                                    :null
                            }
                        </div>
                        <div className="form-box-step2">
                            <div className="form-box-step2-content">
                                <button className="btn1" type="submit" onClick={handleClickPrec}>Précédent</button>
                                <button className="btn2" type="submit" onClick={handleClickStep2}>Suivant</button>
                            </div>
                        </div>
                        <div className="form-box">
                            {
                                !Object.values(secondStepValueError).every((value) => value === "")
                                    ? <span style={{color:'red'}}>Veillez remplir tous les champs avec (*)</span>
                                    :null
                            }
                        </div>

                    </form>
                </div>
           </div>
           <NewsLetters />
           <Footer />
        </div>
    )
}

export default Project;