import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { DarkModeContextProvider, LangueContextProvider } from "./context/modeContext";


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <DarkModeContextProvider>
    <LangueContextProvider>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </LangueContextProvider>
  </DarkModeContextProvider>
);

//app
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
