import React, {useState} from "react";
import { Link } from "react-router-dom"
import Navbar from "../../../component/navbar/Navbar_en"
import faq from "../../../assets/faq.png"
import Footer from "../../../component/footer/Footer_en";
import NewsLetter from "../../../component/news-letter/NewsLetter_en";
import "../../fr/tarifs/tarifs.scss"
import DoneSharpIcon from '@mui/icons-material/DoneSharp';
import KeyboardArrowDownSharpIcon from '@mui/icons-material/KeyboardArrowDownSharp';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import ClearIcon from '@mui/icons-material/Clear';

function TarifsEn() {
    const [displayResp, setDisplayResp] = useState([]);
    const questions = [
        {
        "id": 2,
        "questions": "What are the offers?",
        "reponses": "The offers depend on your needs. We offer personalized offers that meet your various needs while staying within a very affordable price range. You can request a free quote for your project, and after that, you will be able to judge whether the proposed offer is suitable for you or not. We will always be available for potential discussions."
        },
        {
        "id": 3,
        "questions": "What are the procedures to start a contract with us?",
        "reponses": "To start a contract with us, you first need to get in touch with us and let us know about your needs. Then, we will provide you with a quote as soon as possible. After the quote, we will discuss certain terms, and the work can begin."
        },
        {
        "id": 4,
        "questions": "Can I have a customized pricing?",
        "reponses": "Yes, you can have a customized pricing. We prioritize this the most. Since every business is different, it is normal that their needs differ as well. That's why we always take the time to analyze your needs to offer you personalized offers that suit you."
        },
        {
        "id": 5,
        "questions": "Is a possibility of collaboration possible?",
        "reponses": "Beyond the offers we propose, it is also possible to establish a partnership with us for the implementation of your project, especially for emerging projects. If our company is interested in your idea, we will help and support you in its realization."
        },
        {
        "id": 6,
        "questions": "How can I contact you?",
        "reponses": "You can send us a message directly by going to the contact page or by sending us an email at contact@dev-mastermind.com, or via WhatsApp at +226 72528159. You can also contact us using our social media, which can be found in the footer."
        }
    ]
    const handleResp = (id) => {
        if (displayResp.includes(id)) {
          setDisplayResp(displayResp.filter((qid) => qid !== id));
        } else {
          setDisplayResp([...displayResp, id]);
        }
    };
    return(
        <React.Fragment>
            <div className="tarifs">
                <Navbar page="tarifs"/>
                <div className="ordinateur">
                    <div className="first-element">
                        <div className="top">
                            <span>Prices</span>
                            <p>You deserve to have the best support without spending a fortune. We are committed to providing you with high-quality work at an affordable price.</p>
                        </div>
                        <div className="bottom">
                            <div className="box1">
                                <div className="title">Basic</div>
                                <div className="price">
                                    <AttachMoneyIcon />
                                </div>
                                <div className="desc">For individuals and small teams</div>
                                <Link to="/contact"><button>Request a quote</button></Link>
                            </div>
                            <div className="box2">
                                <div className="popular">Most Populaire</div>
                                <div className="title">Pro</div>
                                <div className="price">
                                    <AttachMoneyIcon/>
                                    <AttachMoneyIcon/>
                                </div>
                                <div className="desc">For small and medium-sized businesses</div>
                                <Link to="/contact"><button>Request a quote</button></Link>
                            </div>
                            <div className="box3">
                                <div className="title">Business</div>
                                <div className="price">
                                    <AttachMoneyIcon/>
                                    <AttachMoneyIcon/>
                                    <AttachMoneyIcon/>
                                </div>
                                <div className="desc">For large enterprises</div>
                                <Link to="/contact"><button>Request a quote</button></Link>
                            </div>
                        </div>
                    </div>
                    <div className="second-element">
                        <h2>Compare the offers</h2>
                        <div className="content">
                            <div className="box">
                            <div className="title">Basic</div>
                            <hr />
                            <div className="offre">
                                    <DoneSharpIcon className="icon"/>
                                    <div>Logo if needed</div>
                                </div>
                                <div className="offre">
                                    <DoneSharpIcon className="icon"/>
                                    <div>Website design</div>
                                </div>
                                <div className="offre">
                                    <DoneSharpIcon className="icon"/>
                                    <div>Use of a CMS</div>
                                </div>
                                <div className="offre">
                                    <DoneSharpIcon className="icon"/>
                                    <div>5 pages maximum</div>
                                </div>
                                <div className="offre">
                                    <DoneSharpIcon className="icon"/>
                                    <div>Free domain name for the first year</div>
                                </div>
                                <div className="offre">
                                    <DoneSharpIcon className="icon"/>
                                    <div>Free hosting for the first month</div>
                                </div>
                                <div className="offre">
                                    <DoneSharpIcon className="icon"/>
                                    <div>1 month of free support after delivery</div>
                                </div>
                                <div className="offre">
                                    <DoneSharpIcon className="icon"/>
                                    <div>Administrator interface</div>
                                </div>
                            </div>
                            <div className="box">
                            <div className="title">Pro</div>
                            <hr />
                            <div className="offre">
                                    <DoneSharpIcon className="icon"/>
                                    <div>Logo if needed</div>
                                </div>
                                <div className="offre">
                                    <DoneSharpIcon className="icon"/>
                                    <div>Website design</div>
                                </div>
                                <div className="offre">
                                    <DoneSharpIcon className="icon"/>
                                    <div>Custom development</div>
                                </div>
                                <div className="offre">
                                    <DoneSharpIcon className="icon"/>
                                    <div>10 pages maximum</div>
                                </div>
                                <div className="offre">
                                    <DoneSharpIcon className="icon"/>
                                    <div>Free domain name for the first year</div>
                                </div>
                                <div className="offre">
                                    <DoneSharpIcon className="icon"/>
                                    <div>Free hosting for the first year</div>
                                </div>
                                <div className="offre">
                                    <DoneSharpIcon className="icon"/>
                                    <div>2 month of free support after delivery</div>
                                </div>
                                <div className="offre">
                                    <DoneSharpIcon className="icon"/>
                                    <div>Administrator interface</div>
                                </div>
                                <div className="offre">
                                    <DoneSharpIcon className="icon"/>
                                    <div>Integration of dark and light mode</div>
                                </div>
                                <div className="offre">
                                    <DoneSharpIcon className="icon"/>
                                    <div>Integrated databases</div>
                                </div>
                                <div className="offre">
                                    <DoneSharpIcon className="icon"/>
                                    <div>Back-end development</div>
                                </div>
                            </div>
                            <div className="box">
                            <div className="title">Business</div>
                            <hr />
                            <div className="offre">
                                    <DoneSharpIcon className="icon"/>
                                    <div>Logo if needed</div>
                                </div>
                                <div className="offre">
                                    <DoneSharpIcon className="icon"/>
                                    <div>Website design</div>
                                </div>
                                <div className="offre">
                                    <DoneSharpIcon className="icon"/>
                                    <div>Custom development</div>
                                </div>
                                <div className="offre">
                                    <DoneSharpIcon className="icon"/>
                                    <div>20 pages maximum</div>
                                </div>
                                <div className="offre">
                                    <DoneSharpIcon className="icon"/>
                                    <div>Free domain name for the first year</div>
                                </div>
                                <div className="offre">
                                    <DoneSharpIcon className="icon"/>
                                    <div>Free hosting for the first year</div>
                                </div>
                                <div className="offre">
                                    <DoneSharpIcon className="icon"/>
                                    <div>3 month of free support after delivery</div>
                                </div>
                                <div className="offre">
                                    <DoneSharpIcon className="icon"/>
                                    <div>Administrator interface</div>
                                </div>
                                <div className="offre">
                                    <DoneSharpIcon className="icon"/>
                                    <div>Integration of dark and light mode</div>
                                </div>
                                <div className="offre">
                                    <DoneSharpIcon className="icon"/>
                                    <div>Integrated databases</div>
                                </div>
                                <div className="offre">
                                    <DoneSharpIcon className="icon"/>
                                    <div>Back-end development</div>
                                </div>
                                <div className="offre">
                                    <DoneSharpIcon className="icon"/>
                                    <div>Integration of 3D</div>
                                </div>
                                <div className="offre">
                                    <DoneSharpIcon className="icon"/>
                                    <div>External API integration</div>
                                </div>
                                <div className="offre">
                                    <DoneSharpIcon className="icon"/>
                                    <div>+One feature of your choice</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="third-element">
                        <img src={faq} alt="faq" />
                        <div className="content">
                            <h2>Frequently Asked Questions (FAQ)</h2>
                            <div className="questions-content">
                                {
                                    questions.map((faq) => (
                                        <div key={faq.id}>
                                            <div className="questions">
                                                <span>{faq.questions}</span>
                                                <KeyboardArrowDownSharpIcon 
                                                    className="icon" 
                                                    onClick={() => handleResp(faq.id)}
                                                />
                                            </div>
                                            {displayResp.includes(faq.id) && <div className="reponse">{faq.reponses}</div>}
                                            <hr />
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="tel">
                    <div className="first-element">
                        <div className="top">
                            <span>Prices</span>
                            <p>You deserve to have the best support without spending a fortune. We are committed to providing you with high-quality work at an affordable price.</p>
                        </div>
                        <div className="bottom">
                            <div className="box">
                                <div className="title">Basic</div>
                                <div className="price">
                                    <AttachMoneyIcon className="price"/>
                                </div>
                                <div className="desc">Pour les projets personels</div>
                                <div className="offre">
                                    <DoneSharpIcon className="icon"/>
                                    <div>Logo if needed</div>
                                </div>
                                <div className="offre">
                                    <DoneSharpIcon className="icon"/>
                                    <div>Website design</div>
                                </div>
                                <div className="offre">
                                    <DoneSharpIcon className="icon"/>
                                    <div>Use of a CMS</div>
                                </div>
                                <div className="offre">
                                    <DoneSharpIcon className="icon"/>
                                    <div>5 pages maximum</div>
                                </div>
                                <div className="offre">
                                    <DoneSharpIcon className="icon"/>
                                    <div>Free domain name for the first year</div>
                                </div>
                                <div className="offre">
                                    <DoneSharpIcon className="icon"/>
                                    <div>Free hosting for the first month</div>
                                </div>
                                <div className="offre">
                                    <DoneSharpIcon className="icon"/>
                                    <div>1 month of free support after delivery</div>
                                </div>
                                <div className="offre">
                                    <DoneSharpIcon className="icon"/>
                                    <div>Administrator interface</div>
                                </div>
                                <div className="offre">
                                    <ClearIcon className="icon2"/>
                                    <div>Integration of dark and light mode</div>
                                </div>
                                <div className="offre">
                                    <ClearIcon className="icon2"/>
                                    <div>Integrated databases</div>
                                </div>
                                <div className="offre">
                                    <ClearIcon className="icon2"/>
                                    <div>Back-end development</div>
                                </div>
                                <div className="offre">
                                    <ClearIcon className="icon2"/>
                                    <div>Integration of 3D</div>
                                </div>
                                <div className="offre">
                                    <ClearIcon className="icon2"/>
                                    <div>External API integration</div>
                                </div>
                                <div className="offre">
                                    <ClearIcon className="icon2"/>
                                    <div>+One feature of your choice</div>
                                </div>
                                <Link to="/contact"><button>Request a quote</button></Link>
                            </div>
                            <div className="box">
                                <div className="title">Pro</div>
                                <div className="price">
                                    <AttachMoneyIcon className="price"/>
                                    <AttachMoneyIcon className="price"/>
                                </div>
                                <div className="desc">Pour les petites et moyennes entreprises</div>
                                <div className="offre">
                                    <DoneSharpIcon className="icon"/>
                                    <div>Logo if needed</div>
                                </div>
                                <div className="offre">
                                    <DoneSharpIcon className="icon"/>
                                    <div>Website design</div>
                                </div>
                                <div className="offre">
                                    <DoneSharpIcon className="icon"/>
                                    <div>Custom development</div>
                                </div>
                                <div className="offre">
                                    <DoneSharpIcon className="icon"/>
                                    <div>10 pages maximum</div>
                                </div>
                                <div className="offre">
                                    <DoneSharpIcon className="icon"/>
                                    <div>Free domain name for the first year</div>
                                </div>
                                <div className="offre">
                                    <DoneSharpIcon className="icon"/>
                                    <div>Free hosting for the first year</div>
                                </div>
                                <div className="offre">
                                    <DoneSharpIcon className="icon"/>
                                    <div>2 month of free support after delivery</div>
                                </div>
                                <div className="offre">
                                    <DoneSharpIcon className="icon"/>
                                    <div>Administrator interface</div>
                                </div>
                                <div className="offre">
                                    <DoneSharpIcon className="icon"/>
                                    <div>Integration of dark and light mode</div>
                                </div>
                                <div className="offre">
                                    <DoneSharpIcon className="icon"/>
                                    <div>Integrated databases</div>
                                </div>
                                <div className="offre">
                                    <DoneSharpIcon className="icon"/>
                                    <div>Back-end development</div>
                                </div>
                                <div className="offre">
                                    <ClearIcon className="icon2"/>
                                    <div>Integration of 3D</div>
                                </div>
                                <div className="offre">
                                    <ClearIcon className="icon2"/>
                                    <div>External API integration</div>
                                </div>
                                <div className="offre">
                                    <ClearIcon className="icon2"/>
                                    <div>+One feature of your choice</div>
                                </div>
                                <Link to="/contact"><button>Request a quote</button></Link>
                            </div>
                            <div className="box">
                                <div className="title">Business</div>
                                <div className="price">
                                    <AttachMoneyIcon className="price"/>
                                    <AttachMoneyIcon className="price"/>
                                    <AttachMoneyIcon className="price"/>
                                </div>
                                <div className="desc">Pour les grandes entreprises</div>
                                <div className="offre">
                                    <DoneSharpIcon className="icon"/>
                                    <div>Logo if needed</div>
                                </div>
                                <div className="offre">
                                    <DoneSharpIcon className="icon"/>
                                    <div>Website design</div>
                                </div>
                                <div className="offre">
                                    <DoneSharpIcon className="icon"/>
                                    <div>Custom development</div>
                                </div>
                                <div className="offre">
                                    <DoneSharpIcon className="icon"/>
                                    <div>20 pages maximum</div>
                                </div>
                                <div className="offre">
                                    <DoneSharpIcon className="icon"/>
                                    <div>Free domain name for the first year</div>
                                </div>
                                <div className="offre">
                                    <DoneSharpIcon className="icon"/>
                                    <div>Free hosting for the first year</div>
                                </div>
                                <div className="offre">
                                    <DoneSharpIcon className="icon"/>
                                    <div>3 month of free support after delivery</div>
                                </div>
                                <div className="offre">
                                    <DoneSharpIcon className="icon"/>
                                    <div>Administrator interface</div>
                                </div>
                                <div className="offre">
                                    <DoneSharpIcon className="icon"/>
                                    <div>Integration of dark and light mode</div>
                                </div>
                                <div className="offre">
                                    <DoneSharpIcon className="icon"/>
                                    <div>Integrated databases</div>
                                </div>
                                <div className="offre">
                                    <DoneSharpIcon className="icon"/>
                                    <div>Back-end development</div>
                                </div>
                                <div className="offre">
                                    <DoneSharpIcon className="icon"/>
                                    <div>Integration of 3D</div>
                                </div>
                                <div className="offre">
                                    <DoneSharpIcon className="icon"/>
                                    <div>External API integration</div>
                                </div>
                                <div className="offre">
                                    <DoneSharpIcon className="icon"/>
                                    <div>+One feature of your choice</div>
                                </div>
                                <Link to="/contact"><button>Request a quote</button></Link>
                            </div>
                        </div>
                    </div>
                    <div className="third-element">
                        <img src={faq} alt="faq" />
                        <div className="content">
                            <h2>Frequently Asked Questions (FAQ)</h2>
                            <div className="questions-content">
                                {
                                    questions.map((faq) => (
                                        <div key={faq.id}>
                                            <div className="questions">
                                                <span>{faq.questions}</span>
                                                <KeyboardArrowDownSharpIcon 
                                                    className="icon" 
                                                    onClick={() => handleResp(faq.id)}
                                                />
                                            </div>
                                            {displayResp.includes(faq.id) && <div className="reponse">{faq.reponses}</div>}
                                            <hr />
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <NewsLetter />
                <Footer />
            </div>
        </React.Fragment>
    )
}

export default TarifsEn;