import React, { useState } from "react";

import Navbar from "../../../component/navbar/Navbar_en";
import Footer from "../../../component/footer/Footer_en";
import NewsLetters from "../../../component/news-letter/NewsLetter_en";

import ideas from '../../../assets/ideass.png'

import './projet.scss'

function Projet(){
    const [firstStepValue, setFirstStepValue] = useState(JSON.parse(localStorage.getItem("firstStep")) || {
        project:"",
        finance:"",
        project_name:"",
        budget:"",
        apport:"",
        besoins:"",
        description:"",
        attente:"",
    })
    const [secondStepValue, setSecondStepValue] = useState(JSON.parse(localStorage.getItem("secondStep")) || {
        nom:"",
        prenom:"",
        email:"",
        adresse:"",
        pays:"",
        tel:"",
        profession:"",
    })

    const [firstStepValueError, setFirstStepValueError] = useState({
        project:"",
        finance:"",
        project_name:"",
        budget:"",
        apport:"",
        besoins:"",
        attente:"",
    })
    const [secondStepValueError, setSecondStepValueError] = useState({
        nom:"",
        prenom:"",
        email:"",
        adresse:"",
        pays:"",
        tel:"",
        profession:"",
    })
    const [step, setStep] = useState(JSON.parse(localStorage.getItem("step")) || false);
    const [message, setMessage] = useState("");
    const [error, setError] = useState("");

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFirstStepValue({ ...firstStepValue, [name]: value });
    }
    const handleChangeStep2 = (event) => {
        const { name, value } = event.target;
        setSecondStepValue({ ...secondStepValue, [name]: value });
    }

    const handleClickStep1 = (event) => {
        event.preventDefault();
        const newErrors = {}
        if (!firstStepValue.project) newErrors.project = "Please choose your project type."
        if (!firstStepValue.finance) newErrors.finance = "Please select your type of financing need."
        if (!firstStepValue.project_name) newErrors.project_name = "Please define the name of your project."
        if (!firstStepValue.budget) newErrors.budget = "Please provide the approximate budget for your project."
        if (!firstStepValue.apport) newErrors.apport = "Please specify your personal contribution."
        if (!firstStepValue.besoins) newErrors.besoins= "Please explain the project needs in detail."
        if (!firstStepValue.description) newErrors.description = "Please provide a brief description of your project."
        if (!firstStepValue.attente) newErrors.attente = "Please let us know what you expect from us."
        
        if (Object.keys(newErrors).length > 0) {
            setFirstStepValueError(newErrors);
            return;
        }else{
            localStorage.setItem("firstStep", JSON.stringify(firstStepValue));
            setStep(true);
            localStorage.setItem("step", true);
            setFirstStepValue(null);
        }
    }

    const handleClickPrec = (event) => {
        localStorage.setItem("secondStep", JSON.stringify(secondStepValue));
        setStep(false);
        localStorage.setItem("step", false);
    }
    console.log(secondStepValue)
    const handleClickStep2 = (event) => {
        event.preventDefault();
        const newErrors = {}
        if (!secondStepValue.nom) newErrors.nom = "Please enter your name."
        if (!secondStepValue.prenom) newErrors.prenom = "Please enter your first name."
        if (!secondStepValue.email) newErrors.email = "Please enter your email address."
        if (!secondStepValue.adresse) newErrors.adresse = "Please enter your residential address."
        if (!secondStepValue.pays) newErrors.pays = "Please choose your current country."
        if (!secondStepValue.tel) newErrors.tel = "Please enter your phone number."
        if (!secondStepValue.profession) newErrors.profession = "Please choose your current profession."
        const emailBody = `
    <!DOCTYPE html>
    <html lang="en">
    <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <link href="https://fonts.googleapis.com/css?family=Poppins&display=swap" rel="stylesheet">
        <link href="https://fonts.googleapis.com/css?family=Inter&display=swap" rel="stylesheet">
        <title>Document</title>
    </head>
    <body>
        <div style="
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background-color: white;
            width: 100%;
        ">
            <div style="
                width: 600px;
                background-color: #f4f7fa;
                height: auto; 
                padding: 20px;
            ">
                <h1 style="
                    color: #3164F4;
                    text-align: center;
                ">
                    Projet
                </h1>
                <div>
                    <h3>Informations Personnelles</h3>
                    <table border="1" cellSpacing="0" width="100%">
                        <tr>
                            <td width="50%">
                                Nom
                            </td>
                            <td width="50%">
                                ${secondStepValue.nom}
                            </td>
                        </tr>
                        <tr>
                            <td width="50%">
                                Prenom
                            </td>
                            <td width="50%">
                                ${secondStepValue.prenom}
                            </td>
                        </tr>
                        <tr>
                            <td width="50%">
                                Email
                            </td>
                            <td width="50%">
                                ${secondStepValue.email}
                            </td>
                        </tr>
                        <tr>
                            <td width="50%">
                                Profession
                            </td>
                            <td width="50%">
                                ${secondStepValue.profession}
                            </td>
                        </tr>
                        <tr>
                            <td width="50%">
                                Pays
                            </td>
                            <td width="50%">
                                ${secondStepValue.pays}
                            </td>
                        </tr>
                        <tr>
                            <td width="50%">
                                Adresse
                            </td>
                            <td width="50%">
                                ${secondStepValue.adresse}
                            </td>
                        </tr>
                        <tr>
                            <td width="50%">
                                Telephone
                            </td>
                            <td width="50%">
                                ${secondStepValue.tel}
                            </td>
                        </tr>
                    </table>
                </div>
                <div>
                    <h3>Informations sur le projet</h3>
                    <table border="1" cellSpacing="0" width="100%">
                        <tr>
                            <td width="50%">
                                Type de projet
                            </td>
                            <td width="50%">
                                ${firstStepValue.project}
                            </td>
                        </tr>
                        <tr>
                            <td width="50%">
                                Que souhaitez vous financer ?
                            </td>
                            <td width="50%">
                                ${firstStepValue.finance}
                            </td>
                        </tr>
                        <tr>
                            <td width="50%">
                                Nom du projet
                            </td>
                            <td width="50%">
                                ${firstStepValue.project_name}
                            </td>
                        </tr>
                        <tr>
                            <td width="50%">
                                Budget necessaire
                            <td width="50%">
                                ${firstStepValue.budget}
                            </td>
                        </tr>
                        <tr>
                            <td width="50%">
                                Apport personnelles
                            </td>
                            <td width="50%">
                                ${firstStepValue.apport}
                            </td>
                        </tr>
                        <tr>
                            <td width="50%">
                                Description du projet
                            </td>
                            <td width="50%">
                                ${firstStepValue.description}
                            </td>
                        </tr>
                        <tr>
                            <td width="50%">
                                Besoins Specifiques du projet
                            </td>
                            <td width="50%">
                                ${firstStepValue.besoins}
                            </td>
                        </tr>
                        <tr>
                            <td width="50%">
                                Attente de DevMasterMind
                            </td>
                            <td width="50%">
                                ${firstStepValue.attente}
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </body>
    </html>
        `
        if (Object.keys(newErrors).length > 0) {
            setSecondStepValueError(newErrors);
            return;
        }else{
            localStorage.setItem("secondStep", JSON.stringify(secondStepValue));
            setStep(false);
            localStorage.setItem("step", false);
            const config = {
                SecureToken : "b7b04988-ad60-4bd8-af19-abca19c73d94",
                To : 'contact@dev-mastermind.com',
                From : "pezongomickael67@gmail.com",
                Subject : `Besoin de creation de projet`,
                Body : emailBody,
            }
            if(window.Email){
                window.Email.send(config)
                .then(message => {
                    if(message === "OK"){
                        setFirstStepValue({
                            project:"",
                            finance:"",
                            project_name:"",
                            budget:"",
                            apport:"",
                            besoins:"",
                            description:"",
                            attente:"",
                        });
                        setSecondStepValue({
                            nom:"",
                            prenom:"",
                            email:"",
                            adresse:"",
                            pays:"",
                            tel:"",
                            profession:"",
                        });
                        setSecondStepValueError({
                            nom:"",
                            prenom:"",
                            email:"",
                            adresse:"",
                            pays:"",
                            tel:"",
                            profession:"", 
                        })
                        setFirstStepValueError({
                            project:"",
                            finance:"",
                            project_name:"",
                            budget:"",
                            apport:"",
                            besoins:"",
                            attente:"",
                        })
                        localStorage.removeItem('firstStep');
                        localStorage.removeItem('secondStep');
                        localStorage.removeItem('step')
                        setMessage("success");
                    }else{
                        setError("error");
                    }
                })
                .catch(error => console.error(error));
            }
        }
    }
    console.log(message);
    return(
        <div className="projet">
           <Navbar page="partner" />
           <div className="projet-body">
                <div className="title">
                    <h1>Let's bring your ideas to life together?</h1>
                </div>
                <div className="first-block">
                    <div className="left">
                        <div className="first-block-left-title">
                        Do you have a project idea?
                        </div>
                        <br /><br />
                        <span>
                            Welcome to our page dedicated to project ideas! Do you have a creative vision burning inside you that 
                            you're eager to bring to life, but you need a helping hand to make your idea a reality? You've come to the 
                            right place! Here, we invite you to share your most innovative and ambitious ideas, and if they excite us, 
                            we will be delighted to accompany you in making them a reality. Together, let's build the future of web 
                            development! 🚀
                        </span>
                    </div>
                    <div className="right">
                        <img src={ideas} alt="avez vous une idée" />
                    </div>
                </div>
                <div className={"second-block " + (step ? "inactive" : "")}>
                    <h1 className="etapes">
                        STEP 1/2    
                    </h1>
                    <span>
                        The questions followed by a <span style={{color:'red'}}>*</span> are mandatory.
                    </span>
                    <form>
                    <div className="form-box">
                        <h1>What is your project? <span style={{color:'red'}}>*</span></h1>
                        <div className="radio-form">
                            <input
                            required
                            value="Création d'entreprise"
                            name="project"
                            type="radio"
                            id="create"
                            onChange={handleChange}
                            checked={firstStepValue.project === "Création d'entreprise"}
                            />
                            <label htmlFor="create"><span></span>Business startup</label>
                            <input
                            required
                            value="entreprise existante"
                            name="project"
                            type="radio"
                            id="exist"
                            onChange={handleChange}
                            checked={firstStepValue.project === "entreprise existante"}
                            />
                            <label htmlFor="exist"><span></span>Need help in an existing company.</label>
                            <div className="worm">
                            <div className="worm__segment"></div>
                            </div>
                        </div>
                        {/* Affichez l'erreur si nécessaire */}
                        {firstStepValueError.project && <span style={{ color: 'red' }}>{firstStepValueError.project}</span>}
                    </div>
                        <div className="form-box">
                            <h1>What would you like to fund? <span style={{color:'red'}}>*</span></h1>
                            <div className="radio-form">
                                <input
                                    onChange={handleChange}
                                    required
                                    value="Le project dans son ensemble"
                                    name="finance"
                                    type="radio"
                                    id="b"
                                    checked={firstStepValue.finance === "Le project dans son ensemble"}
                                />
                                <label htmlFor="b"><span></span>The entire project</label>
                                <input
                                    onChange={handleChange}
                                    required
                                    value="Une partie du projet"
                                    name="finance"
                                    type="radio"
                                    id="c"
                                    checked={firstStepValue.finance === "Une partie du projet"}
                                />
                                <label htmlFor="c"><span></span>A part of the project</label>
                                <div className="worm">
                                    <div className="worm__segment"></div>
                                </div>
                            </div>
                            {
                                firstStepValueError.finance
                                    ? <span style={{color:'red'}}>{firstStepValueError.finance}</span>
                                    :null
                            }
                        </div>
                        <div className="form-box">
                            <h1>Name of your project <span style={{color:'red'}}>*</span></h1>
                            <input value={firstStepValue.project_name} onChange={handleChange} required type="text" name="project_name" className="form-box-input" placeholder="Name of your project"/>
                            {
                                firstStepValueError.project_name
                                    ? <span style={{color:'red'}}>{firstStepValueError.project_name}</span>
                                    :null
                            }
                        </div>
                        <div className="form-box">
                            <h1>What is the budget required? <span style={{color:'red'}}>*</span></h1>
                            <input value={firstStepValue.budget} onChange={handleChange} required type="text" name="budget" className="form-box-input" placeholder="The budget is sufficient according to our studies or estimation."/>
                        </div>
                        <div className="form-box">
                            <h1>What is your contribution? <span style={{color:'red'}}>*</span></h1>
                            <input value={firstStepValue.apport} onChange={handleChange} required type="text" name="apport" className="form-box-input" placeholder="Expressed in percentage or skills."/>
                            {
                                firstStepValueError.budget
                                    ? <span style={{color:'red'}}>{firstStepValueError.budget}</span>
                                    :null
                            }
                        </div>
                        <div className="form-box">
                            <h1>Description of the project <span style={{color:'red'}}>*</span></h1>
                            <textarea value={firstStepValue.description} onChange={handleChange} required name="description" id="" cols="30" rows="10" className="attente" />
                            {
                                firstStepValueError.description
                                    ? <span style={{color:'red'}}>{firstStepValueError.description}</span>
                                    :null
                            }
                        </div>
                        <div className="form-box">
                            <h1>What are the specific needs of the project?? <span style={{color:'red'}}>*</span></h1>
                            <textarea value={firstStepValue.besoins} onChange={handleChange} required name="besoins" id="" cols="30" rows="10" />
                            {
                                firstStepValueError.besoins
                                    ? <span style={{color:'red'}}>{firstStepValueError.besoins}</span>
                                    :null
                            }
                        </div>
                        <div className="form-box">
                            <h1>What do you expect from DevMasterMind? <span style={{color:'red'}}>*</span></h1>
                            <textarea value={firstStepValue.attente} onChange={handleChange} required name="attente" id="" cols="30" rows="10" className="attente" />
                            {
                                firstStepValueError.attente
                                    ? <span style={{color:'red'}}>{firstStepValueError.attente}</span>
                                    :null
                            }
                        </div>
                        <div className="form-box">
                            <button type="submit" onClick={handleClickStep1}>Next</button>
                            {
                                !Object.values(firstStepValueError).every((value) => value === "")
                                    ? <span style={{color:'red'}}>Please fill in all the fields with (*)</span>
                                    :null
                            }
                        </div>
                    </form>
                    {
                        message
                            ?
                                <div className="project_success_message">
                                    Message sent successfully.
                                </div>
                            :null
                    }
                    {
                        error
                            ?
                                <div className="project_error_message">
                                    An error occurred. Please try again."
                                </div>
                            :null
                    }
                </div>
                <div className={"second-block " +  (!step ? "inactive" : "")}>
                    <h1 className="etapes">
                        STEP 2/2
                    </h1>
                    <span style={{color:'green'}}>
                        Your personal information
                    </span>
                    <span>
                        The questions followed by a <span style={{color:'red'}}>*</span> are mandatory.
                    </span>
                    <form>
                        <div className="form-box">
                            <h1>Name <span style={{color:'red'}}>*</span></h1>
                            <input value={secondStepValue.nom} onChange={handleChangeStep2} required type="text" name="nom" className="form-box-input" placeholder="Last name"/>
                            {
                                secondStepValueError.nom
                                    ? <span style={{color:'red'}}>{secondStepValueError.nom}</span>
                                    :null
                            }
                        </div>
                        <div className="form-box">
                            <h1>First Name <span style={{color:'red'}}>*</span></h1>
                            <input value={secondStepValue.prenom} onChange={handleChangeStep2} required type="text" name="prenom" className="form-box-input" placeholder="First name"/>
                            {
                                secondStepValueError.prenom
                                    ? <span style={{color:'red'}}>{secondStepValueError.prenom}</span>
                                    :null
                            }
                        </div>
                        <div className="form-box">
                            <h1>Email <span style={{color:'red'}}>*</span></h1>
                            <input value={secondStepValue.email} onChange={handleChangeStep2} required type="email" name="email" className="form-box-input" placeholder="Your email address"/>
                            {
                                secondStepValueError.email
                                    ? <span style={{color:'red'}}>{secondStepValueError.email}</span>
                                    :null
                            }
                        </div>
                        <div className="form-box">
                            <h1>Profession <span style={{color:'red'}}>*</span></h1>
                            <div className="radio-form">
                                <input
                                    onChange={handleChangeStep2}
                                    required
                                    value="etudiant"
                                    name="profession"
                                    type="radio"
                                    id="etudiant"
                                    checked={secondStepValue.profession === "etudiant"}
                                />
                                <label htmlFor="etudiant"><span></span>Studentt</label>
                                <input
                                    onChange={handleChangeStep2}
                                    required
                                    value="particulier"
                                    name="profession"
                                    type="radio"
                                    id="particulier"
                                    checked={secondStepValue.profession === "particulier"}
                                />
                                <label htmlFor="particulier"><span></span>Individual</label>
                                <div className="worm">
                                    <div className="worm__segment"></div>
                                </div>
                            </div>
                            {
                                secondStepValueError.profession
                                    ? <span style={{color:'red'}}>{secondStepValueError.profession}</span>
                                    :null
                            }
                        </div>
                        <div className="form-box">
                            <h1>Country <span style={{color:'red'}}>*</span></h1>
                            <input value={secondStepValue.pays} onChange={handleChangeStep2} required type="text" name="pays" className="form-box-input" placeholder="Country of residence"/>
                            {
                                secondStepValueError.pays
                                    ? <span style={{color:'red'}}>{secondStepValueError.pays}</span>
                                    :null
                            }
                        </div>
                        <div className="form-box">
                            <h1>Address <span style={{color:'red'}}>*</span></h1>
                            <input value={secondStepValue.adresse} onChange={handleChangeStep2} required type="text" name="adresse" className="form-box-input" placeholder="Current address"/>
                            {
                                secondStepValueError.adresse
                                    ? <span style={{color:'red'}}>{secondStepValueError.adresse}</span>
                                    :null
                            }
                        </div>
                        <div className="form-box">
                            <h1>Phone Number <span style={{color:'red'}}>*</span></h1>
                            <input value={secondStepValue.tel} onChange={handleChangeStep2} required type="tel" name="tel" className="form-box-input" placeholder="Phone number"/>
                            {
                                secondStepValueError.tel
                                    ? <span style={{color:'red'}}>{secondStepValueError.tel}</span>
                                    :null
                            }
                        </div>
                        <div className="form-box-step2">
                            <div className="form-box-step2-content">
                                <button className="btn1" type="submit" onClick={handleClickPrec}>Previous</button>
                                <button className="btn2" type="submit" onClick={handleClickStep2}>Send</button>
                            </div>
                        </div>
                        <div className="form-box">
                            {
                                !Object.values(secondStepValueError).every((value) => value === "")
                                    ? <span style={{color:'red'}}>Please fill in all the fields with (*)</span>
                                    :null
                            }
                        </div>

                    </form>
                </div>
           </div>
           <NewsLetters />
           <Footer />
        </div>
    )
}

export default Projet;