import './styles.scss';
import Home from "./pages/fr/home/home"
import Contact from "./pages/fr/contact/Contact"
import Propos from "./pages/fr/propos/Propos"
import Tarifs from "./pages/fr/tarifs/Tarifs"
import HomeEn from "./pages/en/home/home"
import ContactEn from "./pages/en/contact/Contact"
import ProposEn from "./pages/en/propos/Propos"
import TarifsEn from "./pages/en/tarifs/Tarifs"
import Confidentialite from './pages/fr/confidentialites/Confidentialites';
import Conditions from "./pages/fr/conditions/Conditions"
import ConfidentialiteEn from './pages/en/confidentialites/Confidentialites_en';
import ConditionsEn from "./pages/en/conditions/Conditions_en"
import Projet from './pages/fr/projet/Projet';
import Project from './pages/en/projet/Projet';
import { DarkModeContext, LangueContext } from "./context/modeContext";
import { useContext } from 'react';

import { createBrowserRouter,RouterProvider } from "react-router-dom";

function App() {
  const { darkMode } = useContext(DarkModeContext);
  const { lang } = useContext(LangueContext);
  const router_fr = createBrowserRouter([
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "/contact",
      element: <Contact />,
    },
    {
      path: "/about-us",
      element: <Propos />,
    },
    {
      path: "/tarifs",
      element: <Tarifs />,
    },
    {
      path: "/partner",
      element: <Projet/>,
    },
    {
      path: "/conditions_d'utilisation",
      element: <Conditions />,
    },
    {
      path: "/politiques_de_confidentialité",
      element: <Confidentialite />,
    },
    {
      path: "/conditions_d'utilisation",
      element: <Conditions />,
    },
  ]);
  const router_en = createBrowserRouter([
    {
      path: "/",
      element: <HomeEn />,
    },
    {
      path: "/contact",
      element: <ContactEn />,
    },
    {
      path: "/about-us",
      element: <ProposEn />,
    },
    {
      path: "/partner",
      element: <Project/>,
    },
    {
      path: "/tarifs",
      element: <TarifsEn />,
    },
    {
      path: "/conditions_d'utilisation",
      element: <ConditionsEn />,
    },
    {
      path: "/politiques_de_confidentialité",
      element: <ConfidentialiteEn />,
    },
    
  ]);
  return (
    <div className={`theme-${darkMode ? "dark" : "light"}`}>
      {
        lang
          ?<RouterProvider router = {router_fr} />
          :<RouterProvider router = {router_en} />
      }
    </div>
  );
}

export default App;
