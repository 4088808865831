import React from "react";
import { Link } from "react-router-dom"
import Navbar from "../../../component/navbar/Navbar_en";
import propos from "../../../assets/DM2.png";
import mick from "../../../assets/profile.jpeg";
import yasco from "../../../assets/yasco.jpg";
import rachid from "../../../assets/rachid.jpg";
import adamou from "../../../assets/Adamou.jpg";
import junior from "../../../assets/junior.JPG";
import kam from "../../../assets/kam.jpg";
import Josue from "../../../assets/josue ouedraogo.png";
import aziz from "../../../assets/abdoul.jpg";
import sami from "../../../assets/sami.jpeg";
import bg1 from "../../../assets/group.png"
import aude from "../../../assets/aude.jpg"
import "../../fr/propos/propos.scss";
import Footer from "../../../component/footer/Footer_en";
import NewsLetters from "../../../component/news-letter/NewsLetter_en";
import Temoignages from "../../../component/temoignages/Temoignages_en";
import FacebookTwoToneIcon from '@mui/icons-material/FacebookTwoTone';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

function ProposEn() {
  return (
    <React.Fragment>
      <div>
      
        <Navbar page="propos" />
              <div className="propos">
                <div className="first-element">
                  <div className="box1" style={{backgroundImage:`url(${bg1})`, backgroundSize:'cover'}}>
                                        
                  </div>
                  <div className="box">
                    <span>Who are we?</span>
                    <p>
                      DevMasterMind is a cutting-edge company specializing in web and 
                      mobile application development, as well as application management 
                      and maintenance, to help businesses achieve their digital goals. 
                      We are a team of talented and passionate professionals, ready to 
                      use our skills to serve your business. We take pride in offering 
                      innovative and customized solutions to meet your specific needs. 
                      With DevMasterMind, you can expect exceptional service quality, 
                      transparent communication, and a commitment to excellence. We are 
                      determined to help our clients get the most out of their online 
                      presence through high-performing, reliable, and user-friendly web 
                      and mobile applications. We believe that our expertise in 
                      application development, combined with our in-depth knowledge of 
                      emerging trends and technologies, will enable you to stand out 
                      in an ever-evolving market. Choosing DevMasterMind means choosing 
                      a trusted company to help you successfully achieve your digital 
                      goals. We look forward to working with you and turning your vision 
                      into a reality.
                    </p>
                
                    <img src={propos} alt="" />
                    <span>Our mission</span>
                    <p className="p2">
                      Our mission is to provide you with high-quality web and mobile 
                      application development solutions to help your business achieve 
                      its digital goals. We also offer application management and 
                      maintenance services to ensure optimal performance and long-term 
                      reliability. Our goal is to provide innovative and tailored 
                      solutions to help clients enhance their online presence and drive 
                      growth. DevMasterMind aims to stay at the forefront of emerging 
                      trends and technologies in application development to ensure that 
                      the solutions we provide remain relevant and competitive.
                    </p>
                  </div>
                </div>
                {/* <div className="second-element">
                  <div className="content">
                    <div className="left">
                      <h1>Commençons à travailler plus efficacement dès aujourd'hui !</h1>
                    </div>
                    <div className="right">
                      <img className="img2" src={blob1} alt="blob" />
                    </div>
                  </div>
                </div> */}

                <div className="third-element">
                    <h2>Our team</h2>
                    <p>Meet the people behind our organization</p>
                  <div className="team">
                    <div className="box">
                      <div className="info-perso">
                        <img src={mick} alt="profile" />
                        <h3>PEZONGO Mickael</h3>
                        <div className="poste">Founder & Developer</div>
                        <div className="socila-network">
                          <Link target="_blank" rel="noopener noreferrer" to="https://web.facebook.com/mickael.pezongo"><FacebookTwoToneIcon className="facebook"/></Link>
                          <Link target="_blank" rel="noopener noreferrer" to="https://twitter.com/PezongoMickael"><TwitterIcon className="twitter"/></Link>
                          <Link target="_blank" rel="noopener noreferrer" to="https://www.linkedin.com/in/micka%C3%ABl-pezongo-90b814226/"><LinkedInIcon className="linkedin"/></Link>
                        </div>
                      </div>
                    </div>
                    <div className="box">
                      <div className="info-perso">
                        <img src={Josue} alt="profile_Franck_Alain" />
                        <h3>OUEDRAOGO Josue</h3>
                        <div className="poste">Partner & Developer</div>
                        <div className="socila-network">
                          <Link target="_blank" rel="noopener noreferrer" to="https://www.facebook.com/franki.ouangraoua?mibextid=LQQJ4d" ><FacebookTwoToneIcon className="facebook"/></Link>
                          <Link target="_blank" rel="noopener noreferrer" to="https://twitter.com/wangfranck5" ><TwitterIcon className="twitter"/></Link>
                          <Link target="_blank" rel="noopener noreferrer" to="https://www.linkedin.com/in/franck-alain-ouangraoua-91091b1a9" ><LinkedInIcon className="linkedin"/></Link>
                        </div>
                      </div>
                    </div>
                    <div className="box">
                      <div className="info-perso">
                        <img src={adamou} alt="profile_Adamou" />
                        <h3>DIMA  Adamou</h3>
                        <div className="poste">Partner & Developer</div>
                        <div className="socila-network">
                          <Link target="_blank" rel="noopener noreferrer" to="https://web.facebook.com/adamou.dima/"><FacebookTwoToneIcon className="facebook"/></Link>
                          <Link target="_blank" rel="noopener noreferrer" to="https://twitter.com/adamoudima"><TwitterIcon className="twitter"/></Link>
                          <Link target="_blank" rel="noopener noreferrer" to="https://www.linkedin.com/in/adamou-dima-912785160/"><LinkedInIcon className="linkedin"/></Link>
                        </div>
                      </div>
                    </div>
                    <div className="box">
                      <div className="info-perso">
                        <img src={aziz} alt="profile_abdoul_aziz" />
                      <h3>TRAORE Abdoul Aziz</h3>
                        <div className="poste">Partner & Developer</div>
                        <div className="socila-network">
                          <Link target="_blank" rel="noopener noreferrer" to="https://www.facebook.com/abdoulaziz.traore.3910"><FacebookTwoToneIcon className="facebook"/></Link>
                          <Link target="_blank" rel="noopener noreferrer" to="https://www.facebook.com/abdoulaziz.traore.3910"><TwitterIcon className="twitter"/></Link>
                          <Link target="_blank" rel="noopener noreferrer" to="https://www.linkedin.com/in/abdoul-aziz-traore-615939201"><LinkedInIcon className="linkedin"/></Link>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="team">
                  <div className="box">
                      <div className="info-perso">
                        <img src={yasco} alt="profile_Yascinthe" />
                        <h3>ILBOUDO Bénéwendé Yacinthe</h3>
                        <div className="poste">Partner & Designer</div>
                        <div className="socila-network">
                          <Link target="_blank" rel="noopener noreferrer" to="https://www.facebook.com/profile.php?id=100091939973530"><FacebookTwoToneIcon className="facebook"/></Link>
                          <Link target="_blank" rel="noopener noreferrer" to="https://twitter.com/YacintheIL66787"><TwitterIcon className="twitter"/></Link>
                          <Link target="_blank" rel="noopener noreferrer" to="https://www.linkedin.com/in/b%C3%A9n%C3%A9wend%C3%A9-yacinthe-ilboudo-963708230/"><LinkedInIcon className="linkedin"/></Link>
                        </div>
                      </div>
                    </div>
                    <div className="box">
                      <div className="info-perso">
                        <img src={sami} alt="profile_triple_S" />
                        <h3>SOU Sami Samuel</h3>
                        <div className="poste">Partner & Designer</div>
                        <div className="socila-network">
                          <Link target="_blank" rel="noopener noreferrer" to="https://www.facebook.com/profile.php?id=100057758568256&mibextid=LQQJ4d"><FacebookTwoToneIcon className="facebook"/></Link>
                          <Link target="_blank" rel="noopener noreferrer" to="https://twitter.com/sami_samuel_"><TwitterIcon className="twitter"/></Link>
                          <Link target="_blank" rel="noopener noreferrer" to="https://www.linkedin.com/in/sami-samuel-sou-97723826b"><LinkedInIcon className="linkedin"/></Link>
                        </div>
                      </div>
                    </div>
                    <div className="box">
                      <div className="info-perso">
                        <img src={kam} alt="profile_Kam" />
                        <h3>KAM Franck Levie</h3>
                        <div className="poste">Partner & Designer</div>
                        <div className="socila-network">
                          <Link target="_blank" rel="noopener noreferrer" to="https://www.facebook.com/levy.kam.9?mibextid=ZbWKwL"><FacebookTwoToneIcon className="facebook"/></Link>
                          <Link target="_blank" rel="noopener noreferrer" to="https://twitter.com/Franck35715945?t=J1VE8RflAEtcuWNMYDeDtA&s=09"><TwitterIcon className="twitter"/></Link>
                          <Link target="_blank" rel="noopener noreferrer" to="https://www.linkedin.com/in/franck-levie-kam"><LinkedInIcon className="linkedin"/></Link>
                        </div>
                      </div>
                    </div>
                    <div className="box">
                      <div className="info-perso">
                        <img src={junior} alt="profile_junior" />
                        <h3>NIKIEMA Moussa W Edmond Junior</h3>
                        <div className="poste">Partner & Designer</div>
                        <div className="socila-network">
                          <Link target="_blank" rel="noopener noreferrer" to="https://www.facebook.com/junior.nikiema.9" ><FacebookTwoToneIcon className="facebook"/></Link>
                          <Link target="_blank" rel="noopener noreferrer" to="https://twitter.com/Nikiemajunior22?t=ENhWGkgfH3wo-hF8GGvAnA&s=09" ><TwitterIcon className="twitter"/></Link>
                          <Link target="_blank" rel="noopener noreferrer" to="https://www.linkedin.com/in/junior-nikiema-379197259" ><LinkedInIcon className="linkedin"/></Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="team">
                    <div className="box">
                        <div className="info-perso">
                          <img src={rachid} alt="profile" />
                          <h3>NASSA Abdoul Rachid</h3>
                          <div className="poste">Partner & Marketing Manager</div>
                          <div className="socila-network">
                            <Link target="_blank" rel="noopener noreferrer" to="https://www.facebook.com/abdoulrachid.nassa?mibextid=LQQJ4d"><FacebookTwoToneIcon className="facebook"/></Link>
                            <Link target="_blank" rel="noopener noreferrer" to="https://twitter.com/rachidnassa/"><TwitterIcon className="twitter"/></Link>
                            <Link target="_blank" rel="noopener noreferrer" to="https://www.linkedin.com/in/abdoul-rachid-nassa-b6927a19a"><LinkedInIcon className="linkedin"/></Link>
                          </div>
                        </div>
                      </div>
                      <div className="box">
                        <div className="info-perso">
                          <img src={aude} alt="profile_aude" />
                          <h3>AGNIMEL Aude Leatitia</h3>
                          <div className="poste">Partner & Communication Manager</div>
                          <div className="socila-network">
                            <Link target="_blank" rel="noopener noreferrer" to="https://www.facebook.com/audeagnimel?mibextid=LQQJ4d" ><FacebookTwoToneIcon className="facebook"/></Link>
                            <Link target="_blank" rel="noopener noreferrer" to="https://twitter.com/agnimel_aude" ><TwitterIcon className="twitter"/></Link>
                            <Link target="_blank" rel="noopener noreferrer" to="https://www.linkedin.com/in/aude-leatitia-agnimel-communication-marketing" ><LinkedInIcon className="linkedin"/></Link>
                          </div>
                        </div>
                      </div>
                  </div>
                </div>
                <div className="telPropos">
                  <div className="fourth-element">
                    <div className="content">
                        <div className="left">
                        <h1>DMM is ready to start working with you right away</h1>
                        <img src={propos} alt="lando"/>
                        <p>Start optimizing your business today.</p>
                        <Link to="/contact"><button>Request a quote</button></Link>
                        </div>
                    </div>
                  </div>
                </div>
                <div className="ordinateurPropos">
                    <div className="fourth-element">
                      <div className="content">
                        <div className="left">
                          <h1>DMM is ready to start working with you right away</h1>
                          <p>Start optimizing your business today.</p>
                          <Link to="/contact"><button>Request a quote</button></Link>
                        </div>
                        <div className="right">
                          <img src={propos} alt="blob" />
                        </div>
                      </div>
                    </div>
                </div>
                </div>


              <Temoignages />
              <NewsLetters />
              <Footer />
      </div>
    </React.Fragment>
  );
}
export default ProposEn;
