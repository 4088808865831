import StarIcon from '@mui/icons-material/Star';
// import StarHalfIcon from '@mui/icons-material/StarHalf';
// import StarOutlineIcon from '@mui/icons-material/StarOutline';
import fijao from "../../assets/logo_fijao.png"
// import profile from "../../assets/blank_profile.png"
import "./temoignages.scss"

function Temoignages() {
    return(
        <div className="height-element">
                    <div className="top">
                        <h2>Feedback</h2>
                        <p>
                            People love what we do and we want to let you know about it.    
                        </p>
                    </div>
                    <div className="bottom">
                        <div className="box">
                            <p style={{fontSize:'12px'}}>
                                "We express our deepest gratitude to DevMasterMind for their exceptional work, dedication, and 
                                determination in organizing FIJAO 2023. Their invaluable contribution has greatly contributed to our 
                                success. Their technical expertise, creativity, and professionalism have been outstanding. Thank you 
                                for being a trusted partner and being by our side throughout this journey."
                            </p>
                            <div className="star">
                                <StarIcon className="start-icon"/>
                                <StarIcon className="start-icon"/>
                                <StarIcon className="start-icon"/>
                                <StarIcon className="start-icon"/>
                                <StarIcon className="start-icon"/>
                            </div>
                            <div className="info-perso">
                                <img src={fijao} alt="logo fijao" style={{width:'200px', height:'200px'}}/>
                                <h3>The FIJAO team</h3>
                                <span>President of the association</span>
                            </div>
                        </div>
                        <div className="box">
                            <p style={{fontSize:'12px'}}>
                                "We express our deepest gratitude to DevMasterMind for their exceptional work, dedication, and 
                                determination in organizing FIJAO 2023. Their invaluable contribution has greatly contributed to our 
                                success. Their technical expertise, creativity, and professionalism have been outstanding. Thank you 
                                for being a trusted partner and being by our side throughout this journey."
                            </p>
                            <div className="star">
                                <StarIcon className="start-icon"/>
                                <StarIcon className="start-icon"/>
                                <StarIcon className="start-icon"/>
                                <StarIcon className="start-icon"/>
                                <StarIcon className="start-icon"/>
                            </div>
                            <div className="info-perso">
                                <img src={fijao} alt="logo fijao" style={{width:'200px', height:'200px'}}/>
                                <h3>The FIJAO team</h3>
                                <span>President of the association</span>
                            </div>
                        </div>
                        <div className="box">
                            <p style={{fontSize:'12px'}}>
                                "We express our deepest gratitude to DevMasterMind for their exceptional work, dedication, and 
                                determination in organizing FIJAO 2023. Their invaluable contribution has greatly contributed to our 
                                success. Their technical expertise, creativity, and professionalism have been outstanding. Thank you 
                                for being a trusted partner and being by our side throughout this journey."
                            </p>
                            <div className="star">
                                <StarIcon className="start-icon"/>
                                <StarIcon className="start-icon"/>
                                <StarIcon className="start-icon"/>
                                <StarIcon className="start-icon"/>
                                <StarIcon className="start-icon"/>
                            </div>
                            <div className="info-perso">
                                <img src={fijao} alt="logo fijao" style={{width:'200px', height:'200px'}}/>
                                <h3>The FIJAO team</h3>
                                <span>President of the association</span>
                            </div>
                        </div>
                    </div>
        </div>
    )
}

export default Temoignages;