import React from "react"
import { Link } from "react-router-dom"
import { Fade, Slide } from 'react-slideshow-image'
import 'react-slideshow-image/dist/styles.css'
import lando from "../../../assets/DM2.png"
import Navbar from "../../../component/navbar/Navbar"
import Footer from "../../../component/footer/Footer"
import Temoignages from "../../../component/temoignages/Temoignages"
import NewsLetter from "../../../component/news-letter/NewsLetter"
import blob from "../../../assets/idea.png"
import blob1 from "../../../assets/2.png"
import blob2 from "../../../assets/4.png"
import bp from "../../../assets/logo_partenaire.png"
import fijao from "../../../assets/logo_fijao.png"
import logo_lithyusmusic from "../../../assets/Logo_lithyusmusic3.png"
// import blob5 from "../../../assets/blob5.png"
import blob3 from "../../../assets/taget.png"
import dev from "../../../assets/dev.png"
import webDesign from "../../../assets/web.png"
import digital from "../../../assets/marketing-digital.png"
import rs from "../../../assets/social-network.png"
import seo from "../../../assets/seo.png"
import ecommerce from "../../../assets/e-commerce.png"
import bg1 from "../../../assets/colleagues-giving-fist-bump.png"
import bg2 from "../../../assets/still.png"
import bg3 from "../../../assets/web.png"
import bg4 from "../../../assets/bg4.png"
import apero from '../../../assets/apero.png'
import fiftyBg from "../../../assets/genial.png"
import "./home.scss"

function Home(){
    const settings = {
        duration: 5000, // durée de transition en millisecondes
        transitionDuration: 1000,
        infinite: true,
        indicators: true,
        arrows: true,
    };    
    return(

        <React.Fragment>
            <div className="home">
                <Navbar page = "home"/>
                    <div className="tel">
                        <Fade 
                            {...settings}
                            prevArrow={
                                <div 
                                    style={{
                                                fontSize: '0px',
                                                backgroundColor:'white',
                                                height: '70px',
                                                width: '70px',
                                                opacity: '0.5',
                                                borderRadius: '50%',
                                                position:'absolute',
                                                paddingTop: '10px',
                                                paddingLeft: '10px'
                                            }}
                                >{<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 30 24" strokeWidth={2} stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M18.75 19.5l-7.5-7.5 7.5-7.5m-6 15L5.25 12l7.5-7.5" />
                            </svg>
                            }</div>}
                            nextArrow={
                                <div 
                                style={{
                                    fontSize: '0px',
                                    backgroundColor:'white',
                                    opacity: '0.5',
                                    height: '70px',
                                    width: '70px',
                                    borderRadius: '50%',
                                    position:'absolute',
                                    paddingTop: '10px',
                                    paddingLeft: '10px'
                                }}
                                
                                >{<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 30 24" strokeWidth={2} stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M11.25 4.5l7.5 7.5-7.5 7.5m-6-15l7.5 7.5-7.5 7.5" />
                            </svg>
                            }</div>}
                        >
                            <div className="first-element">
                                <div className="box1" style={{backgroundImage:`url(${bg1})`, backgroundSize:'cover'}}>
                                    
                                </div>
                                <div className="box">
                                    <div className="left">
                                        <div className="left-content">
                                            <span className="free-quote">- FREE QUOTE</span>
                                            <h2>Le meilleur moyen de numériser votre entreprise</h2>
                                            <p>
                                                DevMasterMind est une entreprise de pointe spécialisée dans 
                                                le développement d'applications web et mobiles, ainsi que dans 
                                                la gestion et la maintenance d'applications pour aider les 
                                                entreprises à atteindre leurs objectifs numériques.
                                            </p>
                                            <div className="btns">
                                                <div>
                                                    <Link to="/contact"><button className="devi">Demander un devis</button></Link>
                                                </div>
                                                <div>
                                                    <Link to="/tarifs"><button className="see-more">Voir plus</button></Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="first-element">
                                <div className="box1" style={{backgroundImage:`url(${bg2})`, backgroundSize:'cover'}}> 
                                </div>
                                <div className="box">
                                    <div className="left">
                                        <div className="left-content">
                                            <span className="free-quote">- FREE QUOTE</span>
                                            <h2>Le meilleur moyen de numériser votre entreprise</h2>
                                            <p>
                                                DevMasterMind est une entreprise de pointe spécialisée dans 
                                                le développement d'applications web et mobiles, ainsi que dans 
                                                la gestion et la maintenance d'applications pour aider les 
                                                entreprises à atteindre leurs objectifs numériques.
                                            </p>
                                            <div className="btns">
                                                <div>
                                                    <Link to="/contact"><button className="devi">Demander un devis</button></Link>
                                                </div>
                                                <div>
                                                    <Link to="/tarifs"><button className="see-more">Voir plus</button></Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="first-element">
                                <div className="box1" style={{backgroundImage:`url(${bg3})`, backgroundSize:'cover'}}>
                                    
                                </div>
                                <div className="box">
                                    <div className="left">
                                        <div className="left-content">
                                            <span className="free-quote">- FREE QUOTE</span>
                                            <h2>Le meilleur moyen de numériser votre entreprise</h2>
                                            <p>
                                                DevMasterMind est une entreprise de pointe spécialisée dans 
                                                le développement d'applications web et mobiles, ainsi que dans 
                                                la gestion et la maintenance d'applications pour aider les 
                                                entreprises à atteindre leurs objectifs numériques.
                                            </p>
                                            <div className="btns">
                                                <div>
                                                    <Link to="/contact"><button className="devi">Demander un devis</button></Link>
                                                </div>
                                                <div>
                                                    <Link to="/tarifs"><button className="see-more">Voir plus</button></Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="first-element">
                                <div className="box1" style={{backgroundImage:`url(${bg4})`, backgroundSize:'cover'}}>
                                    
                                </div>
                                <div className="box">
                                    <div className="left">
                                        <div className="left-content">
                                            <span className="free-quote">- FREE QUOTE</span>
                                            <h2>Le meilleur moyen de numériser votre entreprise</h2>
                                            <p>
                                                DevMasterMind est une entreprise de pointe spécialisée dans 
                                                le développement d'applications web et mobiles, ainsi que dans 
                                                la gestion et la maintenance d'applications pour aider les 
                                                entreprises à atteindre leurs objectifs numériques.
                                            </p>
                                            <div className="btns">
                                                <div>
                                                    <Link to="/contact"><button className="devi">Demander un devis</button></Link>
                                                </div>
                                                <div>
                                                    <Link to="/tarifs"><button className="see-more">Voir plus</button></Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Fade>
                        <div className="second-element">
                            <div className="title">
                                Plusieurs entreprises nous font confiance
                            </div>
                            <div className="client">
                                <Slide 
                                    prevArrow={
                                        <div 
                                            style={{
                                                        fontSize: '0px',
                                                        backgroundColor:'white',
                                                        height: '50px',
                                                        width: '60px',
                                                        borderRadius: '50%',
                                                        position:'relative',
                                                        paddingTop: '10px',
                                                        paddingLeft: '10px'
                                                    }}
                                        >{<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 30 24" strokeWidth={2} stroke="currentColor" className="w-6 h-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M18.75 19.5l-7.5-7.5 7.5-7.5m-6 15L5.25 12l7.5-7.5" />
                                    </svg>
                                    }</div>}
                                    nextArrow={
                                        <div 
                                        style={{
                                            fontSize: '0px',
                                            backgroundColor:'white',
                                            height: '50px',
                                            width: '60px',
                                            borderRadius: '50%',
                                            position:'relative',
                                            paddingTop: '10px',
                                            paddingLeft: '10px'
                                        }}
                                        
                                        >{<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 30 24" strokeWidth={2} stroke="currentColor" className="w-6 h-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M11.25 4.5l7.5 7.5-7.5 7.5m-6-15l7.5 7.5-7.5 7.5" />
                                    </svg>
                                    }</div>}
                                >
                                    <div className="client-logo">
                                        <Link to='www.lithyusmusic.com'>
                                            <img src={logo_lithyusmusic} alt="" />
                                        </Link>
                                        <img src={fijao} alt="logo of fijao" />
                                        <Link to='https://aperoenergiemines.com'>
                                            <img src={apero} alt="apero energies et mines" />
                                        </Link>
                                    </div>
                                </Slide>
                            </div>
                        </div>
                        <div className="third-element">
                            <div className="content">
                                <div className="left">
                                    <h1>Introduire une nouvelle solution</h1>
                                    <img src={blob} alt="blob"/>
                                    <p>Ajouter plus de fonctionnalités à votre application web</p>
                                    <Link to="/contact"><button>Demander un devis</button></Link>                                   
                                </div>
                            </div>
                        </div>
                        <div className="fourth-element">
                            <div className="right">
                                <span>INTERACTIVE APP</span>
                                <h1>Interagissez avec vos clients</h1>
                                <img src={blob1} alt="blob1" />
                                <p>
                                    Avec nous, créer des applications interactives pour vos clients. Offrez plus d'expérience
                                    à vos clients grâce à une créativité unique qui reflète au mieux votre entreprise
                                </p>
                                <Link to="/tarifs"><button>Voir plus</button></Link>
                            </div>
                        </div>
                        <div className="fifty-element">
                            <div className="box1" style={{backgroundImage:`url(${fiftyBg})`, backgroundSize:'cover'}}>
                                
                            </div>
                            <div className="box">
                                <div className="left">
                                    <span>ECONOMISEZ PLUS</span>
                                    <h1>La qualité au meilleur prix</h1>
                                    <img src={blob2} alt="blob2" />
                                    <p>
                                        Avec nous, créer des applications interactives pour vos clients. 
                                        Ne depensez plus beaucoup pour la digitalisation de vos applications.
                                        Grâce au nombre importants d'experts dans notre entreprise repondant sur plusieurs
                                        domaines, faites vous accompagnés dans la digitalisation sans avoir à depenser
                                        d'énormes sommes d'argent. Offrez plus d'expérience
                                        à vos clients grâce à une créativité unique qui reflète au mieux votre entreprise
                                    </p>
                                    <Link to="/tarifs"><button>Voir plus</button></Link>
                                </div>
                            </div>
                        </div>
                        <div className="sixty-element">
                            <h1>Nos domaines d'activités</h1>
                            <div className="content">
                                <div className="content-box">
                                    <div className="dev">
                                        <img src={dev} alt="" />
                                        <h2>Développement</h2>
                                        <p>
                                            Création, maintenance et gestion de site web et application mobile interactive
                                        </p>
                                    </div>
                                    <div className="dev">
                                        <img src={webDesign} alt="web-design" />
                                        <h2>Web design</h2>
                                        <p>
                                            Nos Webdesigners vous accompagne dans la conception et la réalialisation des 
                                            interfaces graphiques alliant efficacité et esthétisme.                            
                                        </p>
                                    </div>
                                </div>
                                <div className="content-box">
                                    <div className="dev">
                                        <img src={digital} alt="market" />
                                        <h2>Marketing digital</h2>
                                        <p>
                                            Promotion de vos produits, services ou marques utilisant des canaux de communication numériques tels que les réseaux sociaux, les moteurs de recherche, les courriels et les sites Web.
                                        </p>
                                    </div>
                                    <div className="dev">
                                        <img src={seo} alt="SEO" />
                                        <h2>Search Engine Optimization</h2>
                                        <p>
                                            Solutions de SEO transparentes et sur mesure pour augmenter votre visibilité sur Google
                                        </p>
                                    </div>
                                </div>
                                <div className="content-box">
                                    <div className="dev">
                                        <img src={rs} alt="social network" />
                                        <h2>Réseaux sociaux</h2>
                                        <p>
                                            Accompagnement dans votre présence sur les réseaux sociaux, création de contenu, stratégie sociale
                                        </p>
                                    </div>
                                    <div className="dev">
                                        <img src={ecommerce} alt="E-commerce" />
                                        <h2>E-commerce</h2>
                                        <p>
                                            Site Vitrine, Catalogue, E-commerce, Portail. Nous sommes prêts à relever le défi !
                                        </p>
                                    </div>    
                                </div>

                                
                            </div>
                        </div>
                        <div className="seven-element">
                            <div className="content">
                                <div className="left">
                                    <h1>Démarrer Votre Projet Digital Maintenant</h1>
                                    <img src={blob3} alt="blob3" />
                                    <p>Seulement 4 étapes simples pour optimiser les opérations de votre entreprise</p>
                                </div>
                                <div className="right-tel">
                                    <div className="right-content">
                                        <h1>Stratégie</h1>
                                        <p>
                                            Nous mettons en place une stratégie sur mesure pour vous en fonction de vos 
                                            besoins ainsi qu'un plan d'action pour vous permettre d'atteindre vos objectifs
                                        </p>
                                        <hr />
                                    </div>
                                    <div className="right-content">
                                        <h1>Réalisation</h1>
                                        <p>
                                            Nous transformons vos idées en réalités. Nous mettons en place des sites web 
                                            responsives qui s'adaptent à tous les ecrans pour une plus bonne expérience de 
                                            vos clients
                                        </p>
                                        <hr />
                                    </div>
                                    <div className="right-content">
                                        <h1>Design</h1>
                                        <p>
                                            Avec un design sur mesure combinant couleur, mise en page et esthétisme, nous 
                                            donnons vie à vos pages web.
                                        </p>
                                        <hr />
                                    </div>
                                    <div className="right-content">
                                        <h1>Support</h1>
                                        <p>
                                            Nous resterons à vos côtés tout au long du processus d'adaptation et de prise 
                                            en main de vos différentes applications
                                        </p>
                                        <hr />
                                    </div>
                                    <Link to="/contact"><button>Demander un devis</button></Link>
                                </div>
                            </div>
                        </div>
                        <Temoignages />
                        <div className="nine-element">
                            <div className="content">
                                <div className="left">
                                <h1>Commencer avec DMM dès maintenant</h1>
                                <img src={lando} alt="lando"/>
                                <p>Commencez à optimiser votre entreprise dès aujourd'hui.</p>
                                <Link to="/contact"><button>Demander un devis</button></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="ordinateur">
                        <Fade {...settings}>
                            <div className="first-element" >
                                <div className="box1" style={{backgroundImage:`url(${bg1})`, backgroundSize:'cover'}}>
                                    
                                </div>
                                <div className="box" >
                                    <div className="left">
                                        <span className="free-quote">- FREE QUOTE</span>
                                        <h2>Le meilleur moyen de numériser votre entreprise</h2>
                                        <p>
                                            DevMasterMind est une entreprise de pointe spécialisée dans 
                                            le développement d'applications web et mobiles, ainsi que dans 
                                            la gestion et la maintenance d'applications pour aider les 
                                            entreprises à atteindre leurs objectifs numériques.
                                        </p>
                                        <div className="btns">
                                            <div>
                                                
                                                <Link to="/contact"><button className="devi">Demander un devis</button></Link>
                                            </div>
                                            <div>
                                                <Link to="/tarifs"><button className="see-more">Voir plus</button></Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="right">
                                        <img src={lando} alt="lando" />
                                    </div>
                                </div>
                            </div>
                            <div className="first-element">
                                    <div className="box1" style={{backgroundImage:`url(${bg2})`, backgroundSize:'cover'}}>
                                    
                                    </div>
                                    <div className="box" >
                                        <div className="left">
                                            <span className="free-quote">- FREE QUOTE</span>
                                            <h2>Le meilleur moyen de numériser votre entreprise</h2>
                                            <p>
                                                DevMasterMind est une entreprise de pointe spécialisée dans 
                                                le développement d'applications web et mobiles, ainsi que dans 
                                                la gestion et la maintenance d'applications pour aider les 
                                                entreprises à atteindre leurs objectifs numériques.
                                            </p>
                                            <div className="btns">
                                                <div>
                                                    <Link to="/contact"><button className="devi">Demander un devis</button></Link>
                                                </div>
                                                <div>
                                                    <Link to="/tarifs"><button className="see-more">Voir plus</button></Link>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="right">
                                            <img src={lando} alt="lando" />
                                        </div>
                                    </div>
                            </div>
                            <div className="first-element">
                                    <div className="box1" style={{backgroundImage:`url(${bg3})`, backgroundSize:'cover'}}>
                                    
                                    </div>
                                    <div className="box" >
                                        <div className="left">
                                            <span className="free-quote">- FREE QUOTE</span>
                                            <h2>Le meilleur moyen de numériser votre entreprise</h2>
                                            <p>
                                                DevMasterMind est une entreprise de pointe spécialisée dans 
                                                le développement d'applications web et mobiles, ainsi que dans 
                                                la gestion et la maintenance d'applications pour aider les 
                                                entreprises à atteindre leurs objectifs numériques.
                                            </p>
                                            <div className="btns">
                                                <div>
                                                    <Link to="/contact"><button className="devi">Demander un devis</button></Link>
                                                </div>
                                                <div>
                                                    <Link to="/tarifs"><button className="see-more">Voir plus</button></Link>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="right">
                                            <img src={lando} alt="lando" />
                                        </div>
                                    </div>
                            </div>
                            <div className="first-element">
                                    <div className="box1" style={{backgroundImage:`url(${bg4})`, backgroundSize:'cover'}}>
                                    
                                    </div>
                                    <div className="box" >
                                        <div className="left">
                                            <span className="free-quote">- FREE QUOTE</span>
                                            <h2>Le meilleur moyen de numériser votre entreprise</h2>
                                            <p>
                                                DevMasterMind est une entreprise de pointe spécialisée dans 
                                                le développement d'applications web et mobiles, ainsi que dans 
                                                la gestion et la maintenance d'applications pour aider les 
                                                entreprises à atteindre leurs objectifs numériques.
                                            </p>
                                            <div className="btns">
                                                <div>
                                                    <Link to="/contact"><button className="devi">Demander un devis</button></Link>
                                                </div>
                                                <div>
                                                    <Link to="/tarifs"><button className="see-more">Voir plus</button></Link>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="right">
                                            <img src={lando} alt="lando" />
                                        </div>
                                    </div>
                            </div>
                        </Fade>
                        <div className="second-element">
                            <div className="title">
                                Plusieurs entreprises nous font confiance
                            </div>
                            <div >
                                <Slide >
                                    <div className="client-logo">
                                        <Link to="https://lithyusmusic.com/"><img src={logo_lithyusmusic} alt="logo de Lithyus" /></Link>
                                        <img src={fijao} alt="Logo de FIJAO" style={{with:'150px'}} />
                                        <Link to='https://aperoenergiemines.com'>
                                            <img src={apero} alt="apero energies et mines" />
                                        </Link>
                                        <img src={bp} alt="" />
                                    </div>
                                </Slide>
                            </div>
                        </div>
                        <div className="third-element">
                            <div className="content">
                                <div className="left">
                                <h1>Introduire une nouvelle solution</h1>
                                <p>Ajouter plus de fonctionnalités à votre application web</p>
                                <Link to="/contact"><button>Demander un devis</button></Link>
                                </div>
                                <div className="right">
                                <img src={blob} alt="blob"/>
                                </div>
                            </div>
                        </div>
                        <div className="fourth-element">
                            <div className="left">
                                <img src={blob1} alt="blob1" />
                            </div>
                            <div className="right">
                                <span>INTERACTIVE APP</span>
                                <h1>Interagissez avec vos clients</h1>
                                <p>
                                    Avec nous, créer des applications interactives pour vos clients. Offrez plus d'expérience
                                    à vos clients grâce à une créativité unique qui reflète au mieux votre entreprise
                                </p>
                                
                                <Link to="/tarifs"><button>Voir plus</button></Link>
                            </div>
                        </div>
                        <div className="fifty-element">
                            <div className="box1" style={{backgroundImage:`url(${fiftyBg})`, backgroundSize:'cover'}}>
                                
                            </div>
                            <div className="box">
                                <div className="left">
                                    <span>ECONOMISEZ PLUS</span>
                                    <h1>La qualité au meilleur prix</h1>
                                    <p>
                                        Avec nous, créer des applications interactives pour vos clients. 
                                        Ne depensez plus beaucoup pour la digitalisation de vos applications.
                                        Grâce au nombre importants d'experts dans notre entreprise repondant sur plusieurs
                                        domaines, faites vous accompagnés dans la digitalisation sans avoir à depenser
                                        d'énormes sommes d'argent. Offrez plus d'expérience
                                        à vos clients grâce à une créativité unique qui reflète au mieux votre entreprise
                                    </p>
                                    <Link to="/tarifs"><button>Voir plus</button></Link>
                                </div>
                                <div className="right">
                                    <img src={blob2} alt="blob2"/>
                                </div>
                            </div>
                        </div>
                        <div className="sixty-element">
                            <h1>Nos domaines d'activités</h1>
                            <div className="content">
                                <div className="content-box">
                                    <div className="dev">
                                        <img src={dev} alt="" />
                                        <h2>Développement</h2>
                                        <p>
                                            Création, maintenance et gestion de site web et application mobile interactive
                                        </p>
                                    </div>
                                    <div className="dev">
                                        <img src={webDesign} alt="web-design" />
                                        <h2>Web design</h2>
                                        <p>
                                            Nos Webdesigners vous accompagne dans la conception et la réalialisation des 
                                            interfaces graphiques alliant efficacité et esthétisme.                            
                                        </p>
                                    </div>
                                    <div className="dev">
                                        <img src={digital} alt="market" />
                                        <h2>Marketing digital</h2>
                                        <p>
                                            Promotion de vos produits, services ou marques utilisant des canaux de communication numériques tels que les réseaux sociaux, les moteurs de recherche, les courriels et les sites Web.
                                        </p>
                                    </div>
                                </div>
                                <div className="content-box">
                                    <div className="dev">
                                        <img src={seo} alt="SEO" />
                                        <h2>Search Engine Optimization</h2>
                                        <p>
                                            Solutions de SEO transparentes et sur mesure pour augmenter votre visibilité sur Google
                                        </p>
                                    </div>
                                    <div className="dev">
                                        <img src={rs} alt="social network" />
                                        <h2>Réseaux sociaux</h2>
                                        <p>
                                            Accompagnement dans votre présence sur les réseaux sociaux, création de contenu, stratégie sociale
                                        </p>
                                    </div>
                                    <div className="dev">
                                        <img src={ecommerce} alt="E-commerce" />
                                        <h2>E-commerce</h2>
                                        <p>
                                            Site Vitrine, Catalogue, E-commerce, Portail. Nous sommes prêts à relever le défi !
                                        </p>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                        <div className="seven-element">
                            <div className="content">
                                <div className="left">
                                    <img src={blob3} alt="blob3" />
                                    <h1>Démarrer Votre Projet Digital Maintenant</h1>
                                    <p>Seulement 4 étapes simples pour optimiser les opérations de votre entreprise</p>
                                    <Link to="/contact"><button>Demander un devis</button></Link>
                                </div>
                                <div className="right">
                                    <div className="right-content">
                                        <h1>Stratégie</h1>
                                        <p>
                                            Nous mettons en place une stratégie sur mesure pour vous en fonction de vos 
                                            besoins ainsi qu'un plan d'action pour vous permettre d'atteindre vos objectifs
                                        </p>
                                        <hr />
                                    </div>
                                    <div className="right-content">
                                        <h1>Réalisation</h1>
                                        <p>
                                            Nous transformons vos idées en réalités. Nous mettons en place des sites web 
                                            responsives qui s'adaptent à tous les ecrans pour une plus bonne expérience de 
                                            vos clients
                                        </p>
                                        <hr />
                                    </div>
                                    <div className="right-content">
                                        <h1>Design</h1>
                                        <p>
                                            Avec un design sur mesure combinant couleur, mise en page et esthétisme, nous 
                                            donnons vie à vos pages web.
                                        </p>
                                        <hr />
                                    </div>
                                    <div className="right-content">
                                        <h1>Support</h1>
                                        <p>
                                            Nous resterons à vos côtés tout au long du processus d'adaptation et de prise 
                                            en main de vos différentes applications
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Temoignages />
                        <div className="nine-element">
                            <div className="content">
                                <div className="left">
                                <h1>Commencer avec DMM dès maintenant</h1>
                                <p>Commencez à optimiser votre entreprise dès aujourd'hui.</p>
                                <Link to="/contact"><button>Demander un devis</button></Link>
                                </div>
                                <div className="right">
                                <img src={lando} alt="blob"/>
                                </div>
                            </div>
                        </div>
                    </div>
                <NewsLetter />
                <Footer />
            </div>
        </React.Fragment>
    )
}

export default Home;
